import { useMemo } from "react";

/**
 * Will return a string of classnames by filtering out string values of the provided args and concatenating them
 * @param args - is added to the string of classnames if it's a string value
 * @returns a string of classnames, undefined if none of the args are string values
 */
export function cn(...args: (string | undefined | false)[]): string | undefined {
	return args.filter(Boolean).join(" ").trim() || undefined;
}

/**
 * false to undefined.
 * Useful for && conditions where the final value needs to be `T | undefined`
 * @example <a target={f2u(openNew && "_blank")} />
 */
export function f2u<T>(value: T | undefined | false): T | undefined {
	return value === false ? undefined : value;
}

/**
 * Will return the `value` if on the provided `condition` is true, otherwise `undefined` will be returned. This is usefull when html tag attributes are added dynamically
 *
 * @param condition - Wheather or not the value should be returned
 * @param value - Is returned when `condition` is `true`
 * @returns `value` if  `condition` is `true`, otherwise `undefined`
 */
export function valIf<T>(condition: boolean | undefined, value: T): T | undefined {
	return !!condition ? value : undefined;
}

/**
 * Will stop any further events
 * @param e - event that needs to be stopped
 */
export const stopEvent = (e: { stopPropagation: () => void; preventDefault: () => void }): void => {
	e.stopPropagation();
	e.preventDefault();
};

let uniqueIdCounter = 0;
const UNIQUE_ID_WINDOW_KEY = "__globalFancyUniqueIdCounter";
declare global {
	interface Window {
		[UNIQUE_ID_WINDOW_KEY]: number;
	}
}
if (typeof window !== "undefined" && typeof window[UNIQUE_ID_WINDOW_KEY] === "undefined") {
	window[UNIQUE_ID_WINDOW_KEY] = 0;
}

/**
 * Will generate a unique id
 * @param componentName - name of the component which becomes part of the id
 * @returns a unique id string
 */
export function useUniqueId(componentName: string): string {
	return useMemo(() => {
		// check for window for server side rendering, fallback to local counter
		if (typeof window !== "undefined") {
			return `${componentName}-${window[UNIQUE_ID_WINDOW_KEY]++}`;
		} else {
			return `${componentName}-${uniqueIdCounter++}`;
		}
	}, []);
}
