import { BadgeTypes } from "@siteimprove/fancylib";

export type NavigationItem = BaseNavigationItem | LinkItem | ButtonItem;

export type BaseNavigationItem = {
	id: string;
	title: string;
	icon?: string | null;
	children?: NavigationItem[];
	defaultChildId?: string | null;
	observeKey?: string;
	labels?: ItemLabel[];
	visible?: boolean;
};

export type LinkItem = {
	href: string;
	openNew?: boolean;
	onClick?: () => void | Promise<void>;
} & BaseNavigationItem;

export type ButtonItem = {
	onClick: () => void | Promise<void>;
} & BaseNavigationItem;

export type ItemLabel = {
	type: BadgeTypes;
	icon?: JSX.Element;
	text?: string;
	tooltip?: string;
};

export function isLinkItem(item: NavigationItem): item is LinkItem {
	return "href" in item;
}

export function isButtonItem(item: NavigationItem): item is ButtonItem {
	return "onClick" in item;
}

export type NavigateFn<EventType> = (item: NavigationItem, e: EventType) => void;
