/**
 * Enum to help with usage of event.keyCode, since event.code is not supported in IE & Edge
 */
export enum KeyCode {
	Tab = 9,
	Enter = 13,
	Escape = 27,
	Space = 32,
	PageUp = 33,
	PageDown = 34,
	End = 35,
	Home = 36,
	ArrowLeft = 37,
	ArrowUp = 38,
	ArrowRight = 39,
	ArrowDown = 40,
	A = 65,
	Delete = 8,
}

type ListDirection = "vertical" | "horizontal";

/**
 * Returns a new index based on a keyCode and a limit. Assumes a 0-based indexing.
 * @param direction - the visual direction of the list. This changes what arrow keys are used.
 * @param keyCode - the keyCode of the event.
 * @param current - current number in the index.
 * @param limit - the last index in the array, (or length - 1).
 * @returns a new position OR null if the keyCode is unhandled (and
 * in this case you should not stop propagation when using).
 * @example if you have a list of 3 items, and first one is "selected", current would be 0 and
 * limit should be passed in as 2 (length - 1).
 */
export function keyCodeToListPosition(
	direction: ListDirection,
	keyCode: number,
	current: number,
	limit: number
): number | null {
	switch (keyCode) {
		case direction === "vertical" ? KeyCode.ArrowUp : KeyCode.ArrowLeft:
			return Math.max(0, current - 1);
		case KeyCode.PageUp: // 10th previous item
			return Math.max(0, current - 10);
		case direction === "vertical" ? KeyCode.ArrowDown : KeyCode.ArrowRight:
			return Math.min(current + 1, limit);
		case KeyCode.PageDown: // 10th next item
			return Math.min(current + 10, limit);
		case KeyCode.End:
			return limit;
		case KeyCode.Home:
			return 0;
	}

	return null;
}

/**
 * Determines if a blur event (bound to a container) will result in a navigation out of the container
 */
export function isBlurNavigatingOut(e: React.FocusEvent): boolean {
	return e.relatedTarget instanceof Node && !e.currentTarget.contains(e.relatedTarget);
}
