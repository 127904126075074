/* Use translation-fetcher.ps1 to generate keys */

// Location: COMPONENT_LOADER
// Name: LOADING
const loading = {
	"en-US": "Loading",
	"en-GB": "Loading",
	"da-DK": "Indlæser",
	"sv-SE": "Laddar",
	"de-DE": "Lädt",
	"nb-NO": "Laster",
	"fr-FR": "Chargement en cours",
	"es-ES": "Cargando",
	"it-IT": "Caricamento in corso",
	"nl-NL": "Laden",
	"pt-BR": "Carregando",
	"fi-FI": "Ladataan",
	"ja-JP": "読み込み中",
};

// Location: GENERAL_MODAL_TEXTS
// Name: CLOSE_BUTTON
const close = {
	"en-US": "Close",
	"en-GB": "Close",
	"da-DK": "Luk",
	"sv-SE": "Stäng",
	"de-DE": "Schließen",
	"nb-NO": "Lukk",
	"fr-FR": "Fermer",
	"es-ES": "Cerrar",
	"it-IT": "Chiudi",
	"nl-NL": "Sluiten",
	"pt-BR": "Fechar",
	"fi-FI": "Sulje",
	"ja-JP": "閉じる",
};

// Location: GENERAL_INTRO_BOX_TEXTS
// Name: CLOSE_BUTTON_TOOLTIP
const closeSection = {
	"en-US": "Close this section",
	"en-GB": "Close this section",
	"da-DK": "Luk dette afsnit",
	"sv-SE": "Stäng denna sektion",
	"de-DE": "Diesen Abschnitt schließen",
	"nb-NO": "Lukk denne delen",
	"fr-FR": "Fermer cette section",
	"es-ES": "Cerrar esta sección",
	"it-IT": "Chiudi questa sezione",
	"nl-NL": "Deze sectie sluiten",
	"pt-BR": "Fechar esta seção",
	"fi-FI": "Sulje tämä osio",
	"ja-JP": "このセクションを閉じる",
};

// Location: FILTERS
// Name: CLEAR_SELECTED
const clearFilter = {
	"en-US": "Clear selection for dropdown:",
	"en-GB": "Clear selection for dropdown:",
	"da-DK": "Ryd valg for rulleliste:",
	"sv-SE": "Rensa val för listruta:",
	"de-DE": "Auswahl aufheben:",
	"nb-NO": "Fjern valg for rullegardinliste:",
	"fr-FR": "Effacer la sélection pour la liste déroulante :",
	"es-ES": "Borrar selección del menú desplegable:",
	"it-IT": "Cancella la selezione per il menu a discesa:",
	"nl-NL": "Selectie wissen voor dropdown:",
	"pt-BR": "Limpe a seleção para a lista suspensa:",
	"fi-FI": "Poista pudotusvalikon valinta:",
	"ja-JP": "ドロップダウンの選択を削除：",
};

// Location: GLOBAL
// Name: CANCEL
const cancel = {
	"en-US": "Cancel",
	"en-GB": "Cancel",
	"da-DK": "Annuller",
	"sv-SE": "Avbryt",
	"de-DE": "Abbrechen",
	"nb-NO": "Avbryt",
	"fr-FR": "Annuler",
	"es-ES": "Cancelar",
	"it-IT": "Annulla",
	"nl-NL": "Annuleren",
	"pt-BR": "Cancelar",
	"fi-FI": "Peruuta",
	"ja-JP": "キャンセル",
};

// Location: GLOBAL
// Name: CONFIRM
const confirm = {
	"en-US": "Confirm",
	"en-GB": "Confirm",
	"da-DK": "Bekræft",
	"sv-SE": "Bekräfta",
	"de-DE": "Bestätigen",
	"nb-NO": "Bekreft",
	"fr-FR": "Confirmer",
	"es-ES": "Confirmar",
	"it-IT": "Conferma",
	"nl-NL": "Bevestig",
	"pt-BR": "Confirmar",
	"fi-FI": "Vahvista",
	"ja-JP": "確認",
};

// Location: OVERLAY_LAYOUT
// Name: INTRO_BOX_TOGGLE_BUTTON_LABEL
const learn = {
	"en-US": "Learn",
	"en-GB": "Learn",
	"da-DK": "Lær",
	"sv-SE": "Läs mer",
	"de-DE": "Mehr erfahren",
	"nb-NO": "Finn ut mer",
	"fr-FR": "En savoir plus",
	"es-ES": "Más información",
	"it-IT": "Informazioni",
	"nl-NL": "Meer informatie",
	"pt-BR": "Aprender",
	"fi-FI": "Opi",
	"ja-JP": "詳細",
};

// Location: FANCYLIB
// Name: LEARN_MORE_ABOUT
const learnMoreAbout = {
	"en-US": "Learn more about",
	"en-GB": "Learn more about",
	"da-DK": "Få mere at vide om",
	"sv-SE": "Läs mer om",
	"de-DE": "Weitere Informationen über",
	"nb-NO": "Finn ut mer om",
	"fr-FR": "En savoir plus sur",
	"es-ES": "Más información sobre",
	"it-IT": "Ulteriori informazioni su",
	"nl-NL": "Lees meer over",
	"pt-BR": "Saiba mais sobre o",
	"fi-FI": "Lisätietoja",
	"ja-JP": "の詳細",
};

// Location: OVERLAY_LAYOUT
// Name: INTRO_BOX_TOGGLE_BUTTON_ARIA_LABEL
const learnMoreAboutFeature = {
	"en-US": "Learn more about this feature",
	"en-GB": "Learn more about this feature",
	"da-DK": "Få mere at vide om denne funktion",
	"sv-SE": "Läs mer om denna funktion",
	"de-DE": "Erfahren Sie mehr über diese Funktion",
	"nb-NO": "Finn ut mer om denne funksjonen",
	"fr-FR": "En savoir plus sur cette fonctionnalité",
	"es-ES": "Más información sobre esta función",
	"it-IT": "Ulteriori informazioni su questa funzionalità",
	"nl-NL": "Meer informatie over deze functie",
	"pt-BR": "Saiba mais sobre este recurso",
	"fi-FI": "Lue lisää tästä ominaisuudesta",
	"ja-JP": "この機能の詳細はこちら",
};

// Location: FANCYLIB_BUTTON
// Name: ARIA_LABEL_OPEN_NEW_WINDOW
const linkButtonOpenNewAriaLabel = {
	"en-US": "Opens in new tab",
	"en-GB": "Opens in new tab",
	"da-DK": "Åbnes på en ny fane",
	"sv-SE": "Öppnas på en ny flik",
	"de-DE": "Wird in neuer Registerkarte geöffnet",
	"nb-NO": "Åpnes i ny fane",
	"fr-FR": "S’ouvre un nouvel onglet",
	"es-ES": "Se abre en una pestaña nueva",
	"it-IT": "Si apre in una nuova scheda",
	"nl-NL": "Opent in een nieuw tabblad",
	"pt-BR": "",
	"fi-FI": "Avautuu uudessa välilehdessä",
	"ja-JP": "新しいタブで開く",
};

// Location: list
// Name: PIN_BUTTON_LABEL
const pinButtonLabel = {
	"en-US": "Pin to top of list",
	"en-GB": "Pin to top of list",
	"da-DK": "Fastgør til toppen af listen",
	"sv-SE": "Fäst i toppen av listan",
	"de-DE": "An den Anfang der Liste heften",
	"nb-NO": "Fest til toppen av listen",
	"fr-FR": "Épingler au sommet de la liste",
	"es-ES": "Anclar a la cabeza de la lista",
	"it-IT": "Blocca all'inizio dell'elenco",
	"nl-NL": "Vastmaken aan bovenkant lijst",
	"pt-BR": "Fixar no topo da lista",
	"fi-FI": "Kiinnitä kohde luettelon yläosaan",
	"ja-JP": "リスト最上部に固定",
};

// Location: GENERAL_TOOLTIP_TEXTS
// Name: ARIA_ROLEDESCRIPTION
const tooltip = {
	"en-US": "tooltip button",
	"en-GB": "tooltip button",
	"da-DK": "knap til værktøjstip",
	"sv-SE": "knapp för verktygstips",
	"de-DE": "tooltip-Schaltfläche",
	"nb-NO": "verktøytips-knapp",
	"fr-FR": "bouton d'info-bulle",
	"es-ES": "botón de información sobre herramientas",
	"it-IT": "pulsante della descrizione comando",
	"nl-NL": "tooltip knop",
	"pt-BR": "",
	"fi-FI": "työkaluvihjepainike",
	"ja-JP": "",
};

const keys = {
	loading,
	close,
	closeSection,
	clearFilter,
	cancel,
	confirm,
	linkButtonOpenNewAriaLabel,
	learn,
	learnMoreAbout,
	learnMoreAboutFeature,
	pinButtonLabel,
	tooltip,
};

export type TranslationKeys = keyof typeof keys;
export type Language =
	| "en-US"
	| "en-GB"
	| "da-DK"
	| "sv-SE"
	| "de-DE"
	| "nb-NO"
	| "fr-FR"
	| "es-ES"
	| "it-IT"
	| "nl-NL"
	| "pt-BR"
	| "fi-FI"
	| "ja-JP";

const extractLanguage = (lang: Language) =>
	Object.entries(keys).reduce((a, [k, v]) => {
		a[k as TranslationKeys] = v[lang];
		return a;
	}, {} as Record<TranslationKeys, string>);

export const translations: Record<Language, Record<TranslationKeys, string>> = {
	"en-US": extractLanguage("en-US"),
	"en-GB": extractLanguage("en-GB"),
	"da-DK": extractLanguage("da-DK"),
	"sv-SE": extractLanguage("sv-SE"),
	"de-DE": extractLanguage("de-DE"),
	"nb-NO": extractLanguage("nb-NO"),
	"fr-FR": extractLanguage("fr-FR"),
	"es-ES": extractLanguage("es-ES"),
	"it-IT": extractLanguage("it-IT"),
	"nl-NL": extractLanguage("nl-NL"),
	"pt-BR": extractLanguage("pt-BR"),
	"fi-FI": extractLanguage("fi-FI"),
	"ja-JP": extractLanguage("ja-JP"),
};
