import React from "react";
import ReactDOM from "react-dom";
import * as defaultTokens from "@siteimprove/fancylib/design-tokens/variables";
import * as darkTokens from "@siteimprove/fancylib/design-tokens/themes/dark-variables";
import * as legacyTokens from "@siteimprove/fancylib/design-tokens/themes/legacy-variables";

// This is a mapping of theme names to their design tokens and class name.
// A dynamic list of themes is not supported, as we need to define the TypeScript
// types for the design tokens.
const THEMES = {
	default: {
		designTokens: defaultTokens,
		className: "fancy-theme-default",
	},
	dark: {
		designTokens: darkTokens,
		className: "fancy-theme-dark",
	},
	legacy: {
		designTokens: legacyTokens,
		className: "fancy-theme-legacy",
	},
};

export type Theme = keyof typeof THEMES;

function getThemeDefinition(theme: Theme): typeof THEMES[keyof typeof THEMES] {
	return THEMES[theme];
}

export type ThemeProviderProps = {
	children: React.ReactNode;
	theme: Theme;
};

export const ThemeProvider = (props: ThemeProviderProps): JSX.Element => {
	const { children, theme } = props;
	const themeDefinition = getThemeDefinition(theme);
	const context = getOrCreateGlobalFancyThemeContext();
	return (
		<context.Provider value={{ theme }}>
			<div className={themeDefinition.className}>{children}</div>
		</context.Provider>
	);
};

export function useTheme(): Theme {
	const { theme } = React.useContext(getOrCreateGlobalFancyThemeContext());
	return theme;
}

type DesignTokensSet = typeof THEMES[keyof typeof THEMES]["designTokens"];

export function useDesignToken(): DesignTokensSet {
	const theme = useTheme();
	const themeDefinition = getThemeDefinition(theme);
	return themeDefinition.designTokens;
}

export function getThemes(): Theme[] {
	return Object.keys(THEMES) as Theme[];
}

export function useThemedPortal(
	children: React.ReactNode,
	container: Element,
	key?: string | null | undefined
): React.ReactPortal {
	const theme = useTheme();
	const themeDefinition = getThemeDefinition(theme);
	const wrapper = React.createElement(
		"div",
		{ className: themeDefinition.className, key },
		children
	);
	return ReactDOM.createPortal(wrapper, container);
}

export type ThemeContextProps = {
	theme: Theme;
};

const createCtx = () =>
	React.createContext<ThemeContextProps>({
		theme: "default",
	});

const GlobalFancyThemeContextKey = "__globalFancyThemeContext";

declare global {
	interface Window {
		[GlobalFancyThemeContextKey]?: React.Context<ThemeContextProps>;
	}
}

/* This context needs to be globally the same because clients might use
 * code splitting (e.g. pfg) but the context needs to point to the exact same value
 */
let localCtx: React.Context<ThemeContextProps> | null = null;
export const getOrCreateGlobalFancyThemeContext = (): React.Context<ThemeContextProps> => {
	//check for window because of SSR, in SSR we don't care about the context being global
	if (typeof window !== "undefined") {
		const ctx = window[GlobalFancyThemeContextKey];
		//use existing if exist, otherwise create new and save in window
		return ctx ?? (window[GlobalFancyThemeContextKey] = createCtx());
	} else {
		//use existing if exist, otherwise create new and save in local
		return localCtx ?? (localCtx = createCtx());
	}
};
