/* Use translation-fetcher.ps1 to generate keys */

import React from "react";
import { getOrCreateGlobalFancyContext } from "@siteimprove/fancylib";

// Location: MESSAGE
// Name: MESSAGE_NEUTRAL
const messageNeutral = {
	"en-US": "Neutral message",
	"en-GB": "Neutral message",
	"da-DK": "Neutral meddelelse",
	"sv-SE": "Neutralt meddelande",
	"de-DE": "Neutrale Meldung",
	"nb-NO": "Nøytral melding",
	"fr-FR": "Message neutre",
	"es-ES": "Mensaje neutro",
	"it-IT": "Messaggio neutro",
	"nl-NL": "Neutraal bericht",
	"pt-BR": "",
	"fi-FI": "Neutraali viesti",
	"ja-JP": "中立的なメッセージ",
};

// Location: MESSAGE
// Name: MESSAGE_POSITIVE
const messagePositive = {
	"en-US": "Success message",
	"en-GB": "Success message",
	"da-DK": "Succesmeddelelse",
	"sv-SE": "Framgångsmeddelande",
	"de-DE": "Erfolgsmeldung",
	"nb-NO": "Melding om suksess",
	"fr-FR": "Message de réussite",
	"es-ES": "Mensaje de éxito",
	"it-IT": "Messaggio di successo",
	"nl-NL": "Succesbericht",
	"pt-BR": "",
	"fi-FI": "Onnistumisviesti",
	"ja-JP": "成功メッセージ",
};

// Location: MESSAGE
// Name: MESSAGE_WARNING
const messageWarning = {
	"en-US": "Warning message",
	"en-GB": "Warning message",
	"da-DK": "Advarselsmeddelelse",
	"sv-SE": "Varningsmeddelande",
	"de-DE": "Warnmeldung",
	"nb-NO": "Melding med advarsel",
	"fr-FR": "Message d'avertissement",
	"es-ES": "Mensaje de advertencia",
	"it-IT": "Messaggio di avviso",
	"nl-NL": "Waarschuwingsbericht",
	"pt-BR": "",
	"fi-FI": "Varoitusviesti",
	"ja-JP": "警告メッセージ",
};

// Location: MESSAGE
// Name: MESSAGE_CRITICAL
const messageCritical = {
	"en-US": "Critical warning message",
	"en-GB": "Critical warning message",
	"da-DK": "Kritisk advarselsmeddelelse",
	"sv-SE": "Kritiskt varningsmeddelande",
	"de-DE": "Kritische Warnmeldung",
	"nb-NO": "Melding med kritisk advarsel",
	"fr-FR": "Message d'avertissement critique",
	"es-ES": "Mensaje de advertencia crítica",
	"it-IT": "Messaggio di avviso critico",
	"nl-NL": "Kritiek waarschuwingsbericht",
	"pt-BR": "",
	"fi-FI": "Kriittinen varoitusviesti",
	"ja-JP": "重大な警告メッセージ",
};

// Location: MESSAGE
// Name: MESSAGE_NEGATIVE
const messageNegative = {
	"en-US": "Error message",
	"en-GB": "Error message",
	"da-DK": "Fejlmeddelelse",
	"sv-SE": "Felmeddelande",
	"de-DE": "Fehlermeldung",
	"nb-NO": "Feilmelding",
	"fr-FR": "Message d'erreur",
	"es-ES": "Mensaje de error",
	"it-IT": "Messaggio di errore",
	"nl-NL": "Foutmelding",
	"pt-BR": "",
	"fi-FI": "Virheviesti",
	"ja-JP": "エラーメッセージ",
};

// Location: CHART
// Name: SKIP_CHART
const skipChart = {
	"en-US": "Skip chart",
	"en-GB": "Skip chart",
	"da-DK": "Spring diagram over",
	"sv-SE": "Hoppa över diagrammet",
	"de-DE": "Diagramm überspringen",
	"nb-NO": "Hopp over diagrammet",
	"fr-FR": "Ignorer le graphique",
	"es-ES": "Omitir gráfico",
	"it-IT": "Ignora grafico",
	"nl-NL": "Grafiek overslaan",
	"pt-BR": "",
	"fi-FI": "Ohita kaavio",
	"ja-JP": "チャートをスキップ",
};

// Location: ANNOTATIONS
// Name: ANNOTATIONS_LABEL
const annotations = {
	"en-US": "Annotations:",
	"en-GB": "Annotations:",
	"da-DK": "Annotationer:",
	"sv-SE": "Noteringar:",
	"de-DE": "Annotationen:",
	"nb-NO": "Merknader:",
	"fr-FR": "Annotations :",
	"es-ES": "Anotaciones:",
	"it-IT": "Annotazioni:",
	"nl-NL": "Annotaties:",
	"pt-BR": "",
	"fi-FI": "Huomautukset:",
	"ja-JP": "アノテーション：",
};

// Location: ANNOTATIONS
// Name: NO_ANNOTATIONS_FOR_CHART
const noAnnotationsForThisChart = {
	"en-US": "No annotations for this chart.",
	"en-GB": "No annotations for this chart.",
	"da-DK": "Der er ingen annotationer til dette diagram.",
	"sv-SE": "Inga noteringar för detta diagram.",
	"de-DE": "Keine Annotationen für dieses Diagramm.",
	"nb-NO": "Ingen merknader for dette diagrammet.",
	"fr-FR": "Aucune annotation pour ce graphique.",
	"es-ES": "No hay anotaciones para este gráfico.",
	"it-IT": "Nessuna annotazione per questo grafico.",
	"nl-NL": "Geen annotaties voor deze grafiek.",
	"pt-BR": "",
	"fi-FI": "Tätä kaaviota koskevia huomautuksia ei ole.",
	"ja-JP": "このグラフにアノテーションはありません。",
};

// Location: ANNOTATIONS
// Name: CREATE_ANNOTATION_HELP_TEXT
const createAnnotationHelpText = {
	"en-US": 'Create an annotation by clicking the "New annotation" button above.',
	"en-GB": 'Create an annotation by clicking the "New annotation" button above.',
	"da-DK": 'Opret en annotation ved at klikke på knappen "Ny annotation" ovenfor.',
	"sv-SE": "Skapa en notering genom att klicka på Ny notering ovan.",
	"de-DE":
		"Erstellen Sie eine Annotation, indem Sie oben auf die Schaltfläche „Neue Annotation“ klicken.",
	"nb-NO": "Lag en merknad ved å klikke på «Ny merknad»-knappen ovenfor.",
	"fr-FR": "Créez une annotation en cliquant sur le bouton « Nouvelle annotation » ci-dessus.",
	"es-ES": "Cree una anotación haciendo clic en el botón «Nueva anotación».",
	"it-IT": 'Crea un\'annotazione facendo clic sul pulsante "Nuova annotazione" in alto.',
	"nl-NL": 'Maak een annotatie door op de knop "Nieuwe annotatie" hierboven te klikken.',
	"pt-BR": "",
	"fi-FI": "Luo huomautus napsauttamalla yllä olevaa Uusi huomautus -painiketta.",
	"ja-JP": "上記の「新規アノテーション」ボタンをクリックしてアノテーションを作成してください。",
};

// Location: ANNOTATIONS
// Name: NO_ANNOTATIONS_FOR_PERIOD
const noAnnotationsForChartPeriod = {
	"en-US": "No annotations created during this period.",
	"en-GB": "No annotations created during this period.",
	"da-DK": "Der er ikke oprettet nogen annotationer i denne periode.",
	"sv-SE": "Inga noteringar skapades under denna period.",
	"de-DE": "Keine Annotationen während dieses Zeitraums erstellt.",
	"nb-NO": "Ingen merknader ble opprettet i denne perioden.",
	"fr-FR": "Aucune annotation n'a été créée au cours de cette période.",
	"es-ES": "No se han creado anotaciones durante este período.",
	"it-IT": "Nessuna annotazione creata durante questo periodo.",
	"nl-NL": "Er zijn geen annotaties gemaakt in deze periode",
	"pt-BR": "",
	"fi-FI": "Tällä ajanjaksolla ei ole luotu yhtään huomautusta.",
	"ja-JP": "この期間に作成されたアノテーションはありません。",
};

// Location: ANNOTATIONS
// Name: NO_ANNOTATIONS_FOR_FAVORITE
const noAnnotationsForFavorite = {
	"en-US": "No annotations have been marked as favorite for this chart.",
	"en-GB": "No annotations have been marked as favourite for this chart.",
	"da-DK": "Der er ikke markeret nogen annotationer som favorit til dette diagram.",
	"sv-SE": "Inga noteringar har markerats som favoriter för detta diagram.",
	"de-DE": "Für dieses Diagramm wurde keine Annotation als Favorit markiert.",
	"nb-NO": "Ingen merknader har blitt merket som favoritt for dette diagrammet.",
	"fr-FR": "Aucune annotation n'a été ajoutée aux favoris pour ce graphique.",
	"es-ES": "No se han marcado anotaciones como favoritas para este gráfico.",
	"it-IT": "Nessuna annotazione è stata contrassegnata come preferita per questo grafico.",
	"nl-NL": "Er zijn geen annotaties gemarkeerd als favoriet voor deze grafiek.",
	"pt-BR": "",
	"fi-FI": "Yhtäkään huomautusta ei ole merkitty tämän kaavion suosikiksi.",
	"ja-JP": "このグラフにお気に入りとしてマークされたアノテーションはありません。",
};

// Location: ANNOTATIONS
// Name: FAVORITE_ANNOTATION_HELP_TEXT
const noAnnotationsHelpTextForFavorite = {
	"en-US": "Favorite an annotation by clicking the star to the left of it.",
	"en-GB": "Favourite an annotation by clicking the star to the left of it.",
	"da-DK": "Angiv en annotation som favorit ved at klikke på stjernen til venstre for den.",
	"sv-SE": "Markera en notering som favorit genom att klicka på stjärnan till vänster om den.",
	"de-DE":
		"Markieren Sie eine Annotation als Favorit, indem Sie auf den Stern links daneben klicken.",
	"nb-NO": "Merk en merknad som favoritt ved å klikke på stjernen til venstre for den.",
	"fr-FR": "Ajoutez une annotation à vos favoris en cliquant sur l'étoile à sa gauche.",
	"es-ES":
		"Marque como favorita una anotación haciendo clic en la estrella situada a su izquierda.",
	"it-IT": "Aggiungi un'annotazione ai preferiti facendo clic sulla stella alla sua sinistra.",
	"nl-NL": "Maak een annotatie favoriet door op de ster links ervan te klikken.",
	"pt-BR": "",
	"fi-FI": "Merkitse huomautus suosikiksi napsauttamalla sen vasemmalla puolella olevaa tähteä.",
	"ja-JP":
		"アノテーションの左側にある星マークをクリックして、アノテーションをお気に入りに登録してください。",
};

// Location: ANNOTATIONS
// Name: ANNOTATION_DATE_LABEL
const annotationsDate = {
	"en-US": "Date",
	"en-GB": "Date",
	"da-DK": "Dato",
	"sv-SE": "Datum",
	"de-DE": "Datum",
	"nb-NO": "Dato",
	"fr-FR": "Date",
	"es-ES": "Fecha",
	"it-IT": "Data",
	"nl-NL": "Datum",
	"pt-BR": "",
	"fi-FI": "Päivämäärä",
	"ja-JP": "日付",
};

// Location: ANNOTATIONS
// Name: ANNOTATION_COMMENT_LABEL
const annotationsComment = {
	"en-US": "Comment",
	"en-GB": "Comment",
	"da-DK": "Kommentar",
	"sv-SE": "Kommentar",
	"de-DE": "Kommentar",
	"nb-NO": "Kommentar",
	"fr-FR": "Commentaire",
	"es-ES": "Comentario",
	"it-IT": "Commento",
	"nl-NL": "Opmerking",
	"pt-BR": "",
	"fi-FI": "Kommentti",
	"ja-JP": "コメント",
};

// Location: ANNOTATIONS
// Name: ANNOTATION_CREATED_BY_LABEL
const annotationsCreatedBy = {
	"en-US": "Created by",
	"en-GB": "Created by",
	"da-DK": "Oprettet af",
	"sv-SE": "Skapad av",
	"de-DE": "Erstellt von",
	"nb-NO": "Opprettet av",
	"fr-FR": "Créé par",
	"es-ES": "Creado por",
	"it-IT": "Creato da",
	"nl-NL": "Gemaakt door",
	"pt-BR": "",
	"fi-FI": "Luonut",
	"ja-JP": "作成者",
};

// Location: ANNOTATIONS
// Name: ANNOTATION_VISIBILITY_LABEL
const annotationsVisibility = {
	"en-US": "Visibility",
	"en-GB": "Visibility",
	"da-DK": "Synlighed",
	"sv-SE": "Synlighet",
	"de-DE": "Sichtbarkeit",
	"nb-NO": "Synlighet",
	"fr-FR": "Visibilité",
	"es-ES": "Visibilidad",
	"it-IT": "Visibilità",
	"nl-NL": "Zichtbaarheid",
	"pt-BR": "",
	"fi-FI": "Näkyvyys",
	"ja-JP": "表示",
};

// Location: ANNOTATIONS
// Name: ALL_ANNOTATIONS_LABEL
const allAnnotations = {
	"en-US": "All annotations",
	"en-GB": "All annotations",
	"da-DK": "Alle annotationer",
	"sv-SE": "Alla noteringar",
	"de-DE": "Alle Annotationen",
	"nb-NO": "Alle merknader",
	"fr-FR": "Toutes les annotations",
	"es-ES": "Todas las anotaciones",
	"it-IT": "Tutte le annotazioni",
	"nl-NL": "Alle annotaties",
	"pt-BR": "",
	"fi-FI": "Kaikki huomautukset",
	"ja-JP": "すべてのアノテーション",
};

// Location: ANNOTATIONS
// Name: CHART_PERIOD_ANNOTATIONS_LABEL
const chartPeriodAnnotations = {
	"en-US": "Chart period",
	"en-GB": "Chart period",
	"da-DK": "Diagramperiode",
	"sv-SE": "Diagram period",
	"de-DE": "Diagrammzeitraum",
	"nb-NO": "Diagramperiode",
	"fr-FR": "Période du graphique",
	"es-ES": "Período del gráfico",
	"it-IT": "Periodo del grafico",
	"nl-NL": "Grafiek periode",
	"pt-BR": "",
	"fi-FI": "Kaavion ajanjakso",
	"ja-JP": "グラフ期間",
};

// Location: ANNOTATIONS
// Name: FAVORITE_ANNOTATIONS_LABEL
const favoriteAnnotations = {
	"en-US": "Favorite annotations",
	"en-GB": "Favourite annotations",
	"da-DK": "Foretrukne annotationer",
	"sv-SE": "Favoritmarkerade noteringar",
	"de-DE": "Bevorzugte Annotationen",
	"nb-NO": "Favorittmerknader",
	"fr-FR": "Annotations ajoutées aux favoris",
	"es-ES": "Anotaciones favoritas",
	"it-IT": "Annotazioni preferite",
	"nl-NL": "Favoriete annotaties",
	"pt-BR": "",
	"fi-FI": "Suosikkihuomautukset",
	"ja-JP": "お気に入りのアノテーション",
};

// Location: ANNOTATIONS
// Name: FAVORITE_ANNOTATION_LABEL
const favoriteAnnotation = {
	"en-US": "Favorite annotation",
	"en-GB": "Favourite annotation",
	"da-DK": "Angiv annotation som favorit",
	"sv-SE": "Favoritmarkerad notering",
	"de-DE": "Bevorzugte Annotation",
	"nb-NO": "Favorittmerknad",
	"fr-FR": "Annotation ajoutée aux favoris",
	"es-ES": "Anotación favorita",
	"it-IT": "Annotazione preferita",
	"nl-NL": "Favoriete annotatie",
	"pt-BR": "",
	"fi-FI": "Suosikkihuomautus",
	"ja-JP": "アノテーションをお気に入りに登録",
};

// Location: ANNOTATIONS
// Name: FAVORITE_LABEL
const favorite = {
	"en-US": "Favorite",
	"en-GB": "Favourite",
	"da-DK": "Favorit",
	"sv-SE": "Favoriter",
	"de-DE": "Bevorzugt",
	"nb-NO": "Favoritt",
	"fr-FR": "Favoris",
	"es-ES": "Favorito",
	"it-IT": "Preferito",
	"nl-NL": "Favoriet",
	"pt-BR": "",
	"fi-FI": "Suosikki",
	"ja-JP": "お気に入りに登録",
};

// Location: ANNOTATIONS
// Name: NEW_ANNOTATION_LABEL
const newAnnotation = {
	"en-US": "Add annotation",
	"en-GB": "Add annotation",
	"da-DK": "Ny annotation",
	"sv-SE": "Ny notering",
	"de-DE": "Neue Annotation",
	"nb-NO": "Ny merknad",
	"fr-FR": "Nouvelle annotation",
	"es-ES": "Nueva anotación",
	"it-IT": "Nuova annotazione",
	"nl-NL": "Nieuwe annotatie",
	"pt-BR": "",
	"fi-FI": "Uusi huomautus",
	"ja-JP": "新しいアノテーション",
};

// Location: ANNOTATIONS
// Name: CREATE_ANNOTATION_LABEL
const createAnnotation = {
	"en-US": "Create annotation",
	"en-GB": "Create annotation",
	"da-DK": "Opret annotation",
	"sv-SE": "Skapa notering",
	"de-DE": "Annotation erstellen",
	"nb-NO": "Lag merknad",
	"fr-FR": "Créer une annotation",
	"es-ES": "Crear anotación",
	"it-IT": "Crea annotazione",
	"nl-NL": "Annotatie maken",
	"pt-BR": "",
	"fi-FI": "Luo huomautus",
	"ja-JP": "アノテーションを作成",
};

// Location: ANNOTATIONS
// Name: EDIT_ANNOTATION_LABEL
const editAnnotation = {
	"en-US": "Edit annotation",
	"en-GB": "Edit annotation",
	"da-DK": "Rediger annotation",
	"sv-SE": "Redigera notering",
	"de-DE": "Annotation bearbeiten",
	"nb-NO": "Rediger merknad",
	"fr-FR": "Modifier l'annotation",
	"es-ES": "Editar anotación",
	"it-IT": "Modifica annotazione",
	"nl-NL": "Annotatie bewerken",
	"pt-BR": "",
	"fi-FI": "Muokkaa huomautusta",
	"ja-JP": "アノテーションを編集",
};

// Location: ANNOTATIONS
// Name: SAVE_ANNOTATION_LABEL
const saveAnnotation = {
	"en-US": "Save annotation",
	"en-GB": "Save annotation",
	"da-DK": "Gem annotation",
	"sv-SE": "Spara notering",
	"de-DE": "Annotation speichern",
	"nb-NO": "Lagre merknad",
	"fr-FR": "Enregistrer l'annotation",
	"es-ES": "Guardar anotación",
	"it-IT": "Salva annotazione",
	"nl-NL": "Annotatie opslaan",
	"pt-BR": "",
	"fi-FI": "Tallenna huomautus",
	"ja-JP": "アノテーションを保存",
};

// Location: ANNOTATIONS
// Name: SHOW_ANNOTATIONS_LABEL
const showAnnotations = {
	"en-US": "View annotations",
	"en-GB": "View annotations",
	"da-DK": "Vis annotationer",
	"sv-SE": "Visa noteringar",
	"de-DE": "Annotationen anzeigen",
	"nb-NO": "Vis merknader",
	"fr-FR": "Afficher les annotations",
	"es-ES": "Mostrar anotaciones",
	"it-IT": "Mostra annotazioni",
	"nl-NL": "Annotaties weergeven",
	"pt-BR": "",
	"fi-FI": "Näytä huomautukset",
	"ja-JP": "アノテーションを表示",
};

// Location: ANNOTATIONS
// Name: SHOW_ANNOTATIONS_SR_LABEL
const showAnnotationsSrOnly = {
	"en-US": "(annotations table appears below the chart)",
	"en-GB": "(annotations table appears below the chart)",
	"da-DK": "(tabellen med annotationer vises under diagrammet)",
	"sv-SE": "(tabell med noteringar visas nedanför diagrammet)",
	"de-DE": "(Annotationen-Tabelle wird unterhalb des Diagramms angezeigt)",
	"nb-NO": "(merknader-tabellen vises under diagrammet)",
	"fr-FR": "(Le tableau des annotations s'affiche sous le graphique)",
	"es-ES": "(la tabla de anotaciones aparece debajo del gráfico)",
	"it-IT": "(la tabella Annotazioni appare sotto il grafico)",
	"nl-NL": "(annotatietabel verschijnt onder de grafiek)",
	"pt-BR": "",
	"fi-FI": "(huomautustaulukko näkyy kaavion alla)",
	"ja-JP": "(グラフの下にアノテーションテーブルが表示されます)",
};

// Location: ANNOTATIONS
// Name: EDIT_LABEL
const edit = {
	"en-US": "Edit",
	"en-GB": "Edit",
	"da-DK": "Rediger",
	"sv-SE": "Redigera",
	"de-DE": "Bearbeiten",
	"nb-NO": "Rediger",
	"fr-FR": "Modifier",
	"es-ES": "Editar",
	"it-IT": "Modifica",
	"nl-NL": "Bewerken",
	"pt-BR": "",
	"fi-FI": "Muokkaa",
	"ja-JP": "編集",
};

// Location: ANNOTATIONS
// Name: CLOSE_ANNOTATIONS_LABEL
const closeAnnotations = {
	"en-US": "Close annotations",
	"en-GB": "Close annotations",
	"da-DK": "Luk annotationer",
	"sv-SE": "Stäng noteringar",
	"de-DE": "Annotationen schließen",
	"nb-NO": "Lukk merknader",
	"fr-FR": "Fermer les annotations",
	"es-ES": "Cerrar anotaciones",
	"it-IT": "Chiudi annotazioni",
	"nl-NL": "Annotaties sluiten",
	"pt-BR": "",
	"fi-FI": "Sulje huomautukset",
	"ja-JP": "アノテーションを閉じる",
};

// Location: ANNOTATIONS
// Name: SHOW_FILTER_LABEL
const annotationsShowFilter = {
	"en-US": "Show:",
	"en-GB": "Show:",
	"da-DK": "Vis:",
	"sv-SE": "Visa:",
	"de-DE": "Zeigen:",
	"nb-NO": "Vis:",
	"fr-FR": "Afficher :",
	"es-ES": "Mostrar:",
	"it-IT": "Mostra:",
	"nl-NL": "Weergeven:",
	"pt-BR": "",
	"fi-FI": "Näytä:",
	"ja-JP": "表示：",
};

// Location: ANNOTATIONS
// Name: VISIBLE_IN_FIELD_LABEL
const annotationsVisibleInField = {
	"en-US": "Make annotation visible in",
	"en-GB": "Make annotation visible in",
	"da-DK": "Gør annotation synlig i",
	"sv-SE": "Gör noteringar synliga i",
	"de-DE": "Annotation sichtbar machen in",
	"nb-NO": "Gjør merknaden synlig i",
	"fr-FR": "Rendre l'annotation visible dans",
	"es-ES": "Hacer visible la anotación en",
	"it-IT": "Rendi visibile l'annotazione in",
	"nl-NL": "Annotatie zichtbaar maken in",
	"pt-BR": "",
	"fi-FI": "Muuta huomautus näkyväksi seuraavissa:",
	"ja-JP": "アノテーションを表示する対象",
};

// Location: ANNOTATIONS
// Name: COMMENT_FIELD_LABEL
const annotationsCommentField = {
	"en-US": "Comment",
	"en-GB": "Comment",
	"da-DK": "Kommentar",
	"sv-SE": "Kommentar",
	"de-DE": "Kommentar",
	"nb-NO": "Kommentar",
	"fr-FR": "Commentaire",
	"es-ES": "Comentario",
	"it-IT": "Commento",
	"nl-NL": "Opmerking",
	"pt-BR": "",
	"fi-FI": "Kommentti",
	"ja-JP": "コメント",
};

// Location: ANNOTATIONS
// Name: VISIBILITY_FIELD_LABEL
const annotationsVisibilityField = {
	"en-US": "Visibility",
	"en-GB": "Visibility",
	"da-DK": "Synlighed",
	"sv-SE": "Synlighet",
	"de-DE": "Sichtbarkeit",
	"nb-NO": "Synlighet",
	"fr-FR": "Visibilité",
	"es-ES": "Visibilidad",
	"it-IT": "Visibilità",
	"nl-NL": "Zichtbaarheid",
	"pt-BR": "",
	"fi-FI": "Näkyvyys",
	"ja-JP": "表示",
};

// Location: ANNOTATIONS
// Name: SYSTEM_NOTIFICATION_HELP_TEXT
const annotationsSystemNotificationHelpText = {
	"en-US": "System notification",
	"en-GB": "System notification",
	"da-DK": "Systemmeddelelse",
	"sv-SE": "Systemmeddelande",
	"de-DE": "Systembenachrichtigung",
	"nb-NO": "Systemvarsling",
	"fr-FR": "Notification système",
	"es-ES": "Notificación del sistema",
	"it-IT": "Notifica di sistema",
	"nl-NL": "Systeem notificatie",
	"pt-BR": "",
	"fi-FI": "Järjestelmän huomautus",
	"ja-JP": "システム通知",
};

// Location: APPLICATION.ANNOTATIONS
// Name: GLOBAL_TOOLTIP
const annotationsGlobalVisibilityTooltip = {
	"en-US":
		"Occasionally we'll annotate graphs to announce changes which might cause a visible spike or drop in your graphs. These annotations can be seen by all users.",
	"en-GB":
		"Occasionally we'll annotate graphs to announce changes which might cause a visible spike or drop in your graphs. These annotations can be seen by all users.",
	"da-DK":
		"Nogle gange annoterer vi grafer for at fremhæve ændringer, som kan medføre synlige udsving i dine grafer. Disse annotationer kan ses af alle brugere.",
	"sv-SE":
		"Ibland kommenterar vi diagrammen för att meddela dig om ändringar som kan orsaka en synlig topp eller dal i dina diagram. Dessa noteringar kan ses av alla användare.",
	"de-DE":
		"Manchmal fügen wir Diagrammen Anmerkungen hinzu, um zu erklären, welche Änderungen für sichtbare Anstiege oder Rückgänge verantwortlich sein könnten. Diese Anmerkungen können von allen Benutzern gesehen werden.",
	"nb-NO":
		"Fra tid til annen legger vi til merknader på diagrammer for å kunngjøre endringer som kan føre til et synlig hopp eller en synlig reduksjon i diagrammene dine. Disse merknadene kan ses av alle brukere.",
	"fr-FR":
		"Nous annoterons ponctuellement les graphiques pour vous signaler les changements susceptibles de causer un pic ou une baisse visible dans vos courbes. Ces annotations peuvent être vues par tous les utilisateurs.",
	"es-ES":
		"Ocasionalmente, agregaremos notas a los gráficos para anunciar cambios que podrían originar picos o caídas visibles. Estas anotaciones son visibles para todos los usuarios.",
	"it-IT":
		"Di volta in volta, aggiungeremo delle annotazioni ai grafici per annunciare modifiche che potrebbero causare un picco o un calo visibile nei grafici. Queste annotazioni possono essere visualizzate da tutti gli utenti.",
	"nl-NL":
		"We annoteren regelmatig grafieken om wijzigingen aan te kondigen die kunnen leiden tot een zichtbare stijging of daling in je grafieken. Deze annotaties zijn zichtbaar voor alle gebruikers.",
	"pt-BR":
		"Periodicamente, anotaremos os gráficos de modo a anunciar alterações que podem causar pico ou queda visível. Essas anotações podem ser vistas por todos os usuários.",
	"fi-FI":
		"Lisäämme toisinaan kaavioihin huomautuksia, joissa kuvataan selkeitä nousuja tai laskuja aiheuttaneita muutoksia. Kaikki käyttäjät näkevät nämä huomautukset.",
	"ja-JP":
		"当社では時折グラフにアノテーションを追加して、グラフで目に見える上昇や下降を引き起こす可能性のある変更を発表することがあります。 これらのアノテーションは、すべてのユーザーが見ることができます。",
};

// Location: ANNOTATIONS
// Name: EVERYONE_LABEL
const everyone = {
	"en-US": "Everyone",
	"en-GB": "Everyone",
	"da-DK": "Alle",
	"sv-SE": "Alla",
	"de-DE": "Alle",
	"nb-NO": "Alle",
	"fr-FR": "Tout le monde",
	"es-ES": "Todos",
	"it-IT": "Tutti",
	"nl-NL": "Iedereen",
	"pt-BR": "",
	"fi-FI": "Kaikki",
	"ja-JP": "全員",
};

// Location: ANNOTATIONS
// Name: ONLY_ME_LABEL
const onlyMe = {
	"en-US": "Only me",
	"en-GB": "Only me",
	"da-DK": "Kun mig",
	"sv-SE": "Bara jag",
	"de-DE": "Nur ich",
	"nb-NO": "Bare meg",
	"fr-FR": "Moi uniquement",
	"es-ES": "Solo yo",
	"it-IT": "Solo io",
	"nl-NL": "Alleen ik",
	"pt-BR": "",
	"fi-FI": "Vain minä",
	"ja-JP": "自分専用",
};

// Location: TABLE
// Name: SKIP_TABLE
const skipTable = {
	"en-US": "Skip table",
	"en-GB": "Skip table",
	"da-DK": "Spring tabel over",
	"sv-SE": "Hoppa över tabellen",
	"de-DE": "Tabelle überspringen",
	"nb-NO": "Hopp over tabellen",
	"fr-FR": "Ignorer le tableau",
	"es-ES": "Omitir tabla",
	"it-IT": "Ignora tabella",
	"nl-NL": "Tabel overslaan",
	"pt-BR": "",
	"fi-FI": "Ohita taulukko",
	"ja-JP": "テーブルをスキップ",
};

// Location: TABLE
// Name: TABLE_TOOLBAR
const tableToolbarLabel = {
	"en-US": "Table toolbar",
	"en-GB": "Table toolbar",
	"da-DK": "Tabelværktøjslinje",
	"sv-SE": "Verktygsfält för tabell",
	"de-DE": "Tabellen-Symbolleiste",
	"nb-NO": "Verktøylinje for tabell",
	"fr-FR": "Barre d'outils du tableau",
	"es-ES": "Barra de herramientas de tabla",
	"it-IT": "Barra degli strumenti della tabella",
	"nl-NL": "Tabel werkbalk",
	"pt-BR": "",
	"fi-FI": "Taulukon työkalupalkki",
	"ja-JP": "テーブルツールバー",
};

// Location: TABLE
// Name: TABLE_NO_DATA_STATE
const tableNoDataStateLabel = {
	"en-US": "No data to display",
	"en-GB": "No data to display",
	"da-DK": "Der er ingen data at vise",
	"sv-SE": "Inga data att visa",
	"de-DE": "Keine Daten zum Anzeigen vorhanden",
	"nb-NO": "Ingen data å vise",
	"fr-FR": "Aucune donnée à afficher",
	"es-ES": "No hay datos para mostrar",
	"it-IT": "Non ci sono dati da visualizzare",
	"nl-NL": "Geen data om weer te geven. ",
	"pt-BR": "",
	"fi-FI": "Ei näytettäviä tietoja",
	"ja-JP": "表示するデータがありません",
};

// Location: GENERAL_TABLE_TEXTS
// Name: COLUMN_HEADER_ROLE_DESCRIPTION
// The values should be lowercase like any native ARIA role
const columnHeaderRoleDescription = {
	"en-US": "sort button",
	"en-GB": "sort button",
	"da-DK": "knappen sorter",
	"sv-SE": "sorteringsknapp",
	"de-DE": "schaltfläche 'sortieren'",
	"nb-NO": "sorteringsknapp",
	"fr-FR": "bouton trier",
	"es-ES": "botón ordenar",
	"it-IT": "pulsante ordina",
	"nl-NL": "sorteerknop",
	"pt-BR": "",
	"fi-FI": "lajittelupainike",
	"ja-JP": "並べ替えボタン",
};

// Location: GENERAL_DIALOG_TEXTS
// Name: CLOSE_DIALOG
const closeDialog = {
	"en-US": "Close dialog",
	"en-GB": "Close dialog",
	"da-DK": "Luk dialogboks",
	"sv-SE": "Stäng dialogrutan",
	"de-DE": "Dialogfeld schließen",
	"nb-NO": "Lukk dialog",
	"fr-FR": "Fermer la boîte de dialogue",
	"es-ES": "Cerrar diálogo",
	"it-IT": "Chiudi finestra di dialogo",
	"nl-NL": "Dialoog sluiten",
	"pt-BR": "",
	"fi-FI": "Sulje valintaikkuna",
	"ja-JP": "ダイアログを閉じる",
};

// Location: GENERAL_SPARK_LINE_TEXTS
// Name: ARIA_LABEL
const sparkLineAriaLabel = {
	"en-US": "Simple trend chart with [NUMBER_OF_POINTS] data points",
	"en-GB": "Simple trend chart with [NUMBER_OF_POINTS] data points",
	"da-DK": "Enkelt tendensdiagram med [NUMBER_OF_POINTS] datapunkter",
	"sv-SE": "Enkelt trenddiagram med [NUMBER_OF_POINTS] datapunkter",
	"de-DE": "Einfaches Trenddiagramm mit [NUMBER_OF_POINTS] Datenpunkten",
	"nb-NO": "Enkelt trenddiagram med [NUMBER_OF_POINTS] datapunkter",
	"fr-FR": "Graphique de tendance simple avec [NUMBER_OF_POINTS] points de données",
	"es-ES": "Gráfico de tendencias simples con [NUMBER_OF_POINTS] puntos de datos",
	"it-IT": "Grafico delle tendenze semplice con [NUMBER_OF_POINTS] punti dati",
	"nl-NL": "Eenvoudige trendgrafiek met [NUMBER_OF_POINTS] data punten",
	"pt-BR": "",
	"fi-FI": "Yksinkertainen trendikaavio, jossa on [NUMBER_OF_POINTS] datapistettä",
	"ja-JP": "データポイント数が[NUMBER_OF_POINTS]個の単純なトレンドチャート",
};

// TODO create translation key on server side
const remove = {
	"en-US": "Remove",
	"en-GB": "Remove",
	"da-DK": "",
	"sv-SE": "",
	"de-DE": "",
	"nb-NO": "",
	"fr-FR": "",
	"es-ES": "",
	"it-IT": "",
	"nl-NL": "",
	"pt-BR": "",
	"fi-FI": "",
	"ja-JP": "",
};

// Location: GENERAL_SELECT_TEXTS
// Name: SEARCH
const search = {
	"en-US": "Search",
	"en-GB": "Search",
	"da-DK": "Søg",
	"sv-SE": "Sök",
	"de-DE": "Suche",
	"nb-NO": "Søk",
	"fr-FR": "Recherche",
	"es-ES": "Buscar",
	"it-IT": "Cerca",
	"nl-NL": "Zoeken",
	"pt-BR": "",
	"fi-FI": "Haku",
	"ja-JP": "検索",
};

// Location: GENERAL_SELECT_TEXTS
// Name: NO_MATCHES
const noMatches = {
	"en-US": "No matches",
	"en-GB": "No matches",
	"da-DK": "Ingen matches",
	"sv-SE": "Inga träffar",
	"de-DE": "Keine Treffer",
	"nb-NO": "Ingen treff",
	"fr-FR": "Aucune correspondance",
	"es-ES": "No hay coincidencias",
	"it-IT": "Nessuna corrispondenza",
	"nl-NL": "Geen overeenkomsten",
	"pt-BR": "",
	"fi-FI": "Ei osumia",
	"ja-JP": "一致なし",
};

// Location: GENERAL_SELECT_TEXTS
// Name: CREATE
const create = {
	"en-US": "Create",
	"en-GB": "Create",
	"da-DK": "Opret",
	"sv-SE": "Skapa",
	"de-DE": "Erstellen",
	"nb-NO": "Opprett",
	"fr-FR": "Créer",
	"es-ES": "Crear",
	"it-IT": "Crea",
	"nl-NL": "Maken",
	"pt-BR": "",
	"fi-FI": "Luo",
	"ja-JP": "作成",
};

// Location: GENERAL_SELECT_TEXTS
// Name: SELECT
const select = {
	"en-US": "Select",
	"en-GB": "Select",
	"da-DK": "Vælg",
	"sv-SE": "Välj",
	"de-DE": "Auswählen",
	"nb-NO": "Velg",
	"fr-FR": "Sélectionner",
	"es-ES": "Seleccionar",
	"it-IT": "Seleziona",
	"nl-NL": "Selecteren",
	"pt-BR": "",
	"fi-FI": "Valitse",
	"ja-JP": "選択",
};

// Location: GENERAL_SELECT_TEXTS
// Name: SELECTED
const selected = {
	"en-US": "selected",
	"en-GB": "selected",
	"da-DK": "valgt",
	"sv-SE": "markerade",
	"de-DE": "ausgewählt",
	"nb-NO": "valgt",
	"fr-FR": "Sélectionné(e)(s)",
	"es-ES": "seleccionados",
	"it-IT": "selezionato",
	"nl-NL": "geselecteerd",
	"pt-BR": "",
	"fi-FI": "valittu",
	"ja-JP": "選択済み",
};

// Location: GENERAL_SELECT_TEXTS
// Name: SELECTED_ITEMS
const selectedItems = {
	"en-US": "Selected items",
	"en-GB": "Selected items",
	"da-DK": "Valgte elementer",
	"sv-SE": "Valda objekt",
	"de-DE": "Ausgewählte Elemente",
	"nb-NO": "Valgte elementer",
	"fr-FR": "Éléments sélectionnés",
	"es-ES": "Elementos seleccionados",
	"it-IT": "Elementi selezionati",
	"nl-NL": "Geselecteerde items",
	"pt-BR": "",
	"fi-FI": "Valitut kohteet",
	"ja-JP": "選択した項目",
};

// Location: GENERAL_SELECT_TEXTS
// Name: SELECT_ALL
const selectAll = {
	"en-US": "Select all",
	"en-GB": "Select all",
	"da-DK": "Vælg alle",
	"sv-SE": "Välj alla",
	"de-DE": "Alle auswählen",
	"nb-NO": "Velg alle",
	"fr-FR": "Sélectionner tout",
	"es-ES": "Seleccionar todo",
	"it-IT": "Seleziona tutto",
	"nl-NL": "Alles selecteren",
	"pt-BR": "",
	"fi-FI": "Valitse kaikki",
	"ja-JP": "すべて選択",
};

// Location: GENERAL_SELECT_TEXTS
// Name: SELECT_ALL_MATCHING
const selectAllMatching = {
	"en-US": "Select all matching",
	"en-GB": "Select all matching",
	"da-DK": "Vælg alle, der matcher",
	"sv-SE": "Välj alla som matchar",
	"de-DE": "Alle übereinstimmenden auswählen",
	"nb-NO": "Velg alle samsvarende",
	"fr-FR": "Sélectionner toutes les correspondances",
	"es-ES": "Seleccionar todas las coincidencias",
	"it-IT": "Seleziona tutte le corrispondenze",
	"nl-NL": "Selecteer alle overeenkomende",
	"pt-BR": "",
	"fi-FI": "Valitse kaikki vastaavat",
	"ja-JP": "すべての一致を選択",
};

// Location: GENERAL_SELECT_TEXTS
// Name: DESELECT
const deselect = {
	"en-US": "Deselect",
	"en-GB": "Deselect",
	"da-DK": "Fravælg",
	"sv-SE": "Avmarkera",
	"de-DE": "Abwählen",
	"nb-NO": "Opphev valg",
	"fr-FR": "Désélectionner",
	"es-ES": "Anular la selección",
	"it-IT": "Deseleziona",
	"nl-NL": "Deselecteren",
	"pt-BR": "",
	"fi-FI": "Poista valinta",
	"ja-JP": "選択解除",
};

// Location: GENERAL_SELECT_TEXTS
// Name: DESELECT_ALL
const deselectAll = {
	"en-US": "Deselect all",
	"en-GB": "Deselect all",
	"da-DK": "Fravælg alle",
	"sv-SE": "Avmarkera alla",
	"de-DE": "Alle abwählen",
	"nb-NO": "Opphev valg av alle",
	"fr-FR": "Désélectionner tout",
	"es-ES": "Anular la selección de todo",
	"it-IT": "Deseleziona tutto",
	"nl-NL": "Alles deselecteren",
	"pt-BR": "",
	"fi-FI": "Poista kaikkien valinnat",
	"ja-JP": "すべて選択解除",
};

// Location: GENERAL_SELECT_TEXTS
// Name: MAX
const max = {
	"en-US": "max",
	"en-GB": "max",
	"da-DK": "maks",
	"sv-SE": "högst",
	"de-DE": "max",
	"nb-NO": "maks",
	"fr-FR": "max",
	"es-ES": "máx",
	"it-IT": "massimo",
	"nl-NL": "max",
	"pt-BR": "",
	"fi-FI": "enintään",
	"ja-JP": "最大",
};

// Location: GENERAL_DCI_GAUGE_TEXTS
// Name: DCI_SCORE_GAUGE
const dciScoreGauge = {
	"en-US": "DCI Score gauge",
	"en-GB": "DCI Score gauge",
	"da-DK": "Måler for DCI Score",
	"sv-SE": "DCI Score-mätare",
	"de-DE": "Anzeige des DCI Score",
	"nb-NO": "DCI Score-måler",
	"fr-FR": "Jauge DCI Score",
	"es-ES": "Indicador de DCI Score",
	"it-IT": "Indicatore DCI Score",
	"nl-NL": "DCI Score graadmeter",
	"pt-BR": "",
	"fi-FI": "DCI Score -mittari",
	"ja-JP": "DCI Scoreゲージ",
};

// Location: GENERAL_DCI_GAUGE_TEXTS
// Name: SITE_TARGET
const siteTarget = {
	"en-US": "Site target",
	"en-GB": "Site target",
	"da-DK": "Sitemål",
	"sv-SE": "Webbplatsmål",
	"de-DE": "Konformitätsziel",
	"nb-NO": "Nettstedsmål",
	"fr-FR": "Cible du site",
	"es-ES": "Objetivo del sitio web",
	"it-IT": "Obiettivo sito",
	"nl-NL": "Website doel",
	"pt-BR": "",
	"fi-FI": "Sivuston tavoitetaso",
	"ja-JP": "サイトの目標",
};

// Location: GENERAL_DCI_GAUGE_TEXTS
// Name: INDUSTRY_BENCHMARK
const industryBenchmark = {
	"en-US": "Industry benchmark",
	"en-GB": "Industry benchmark",
	"da-DK": "Benchmark for branchen",
	"sv-SE": "Industri Benchmark",
	"de-DE": "Branchenvergleichswert",
	"nb-NO": "Bransje-benchmark",
	"fr-FR": "Benchmark sectoriel",
	"es-ES": "Media del sector",
	"it-IT": "Benchmark del settore",
	"nl-NL": "Industrie benchmark",
	"pt-BR": "",
	"fi-FI": "Toimialan keskimääräinen tulos",
	"ja-JP": "業界ベンチマーク",
};

// Location: GENERAL_TREND_FORMATTER_TEXTS
// Name: DIRECTION_UP
const directionUp = {
	"en-US": "Up",
	"en-GB": "Up",
	"da-DK": "Op",
	"sv-SE": "Upp",
	"de-DE": "Nach oben",
	"nb-NO": "Opp",
	"fr-FR": "Haut",
	"es-ES": "Arriba",
	"it-IT": "Freccia Su",
	"nl-NL": "Omhoog",
	"pt-BR": "",
	"fi-FI": "Ylös",
	"ja-JP": "アップ",
};

// Location: GENERAL_TREND_FORMATTER_TEXTS
// Name: DIRECTION_DOWN
const directionDown = {
	"en-US": "Down",
	"en-GB": "Down",
	"da-DK": "Ned",
	"sv-SE": "Ner",
	"de-DE": "Nach unten",
	"nb-NO": "Ned",
	"fr-FR": "Bas",
	"es-ES": "Abajo",
	"it-IT": "Freccia Giù",
	"nl-NL": "Omlaag",
	"pt-BR": "",
	"fi-FI": "Alas",
	"ja-JP": "ダウン",
};

// Location: GENERAL_TREND_FORMATTER_TEXTS
// Name: DIRECTION_NEUTRAL
const directionNeutral = {
	"en-US": "Neutral",
	"en-GB": "Neutral",
	"da-DK": "Neutral",
	"sv-SE": "Neutral",
	"de-DE": "Neutral",
	"nb-NO": "Nøytral",
	"fr-FR": "Neutre",
	"es-ES": "Neutro",
	"it-IT": "Freccia orizzontale",
	"nl-NL": "Neutraal",
	"pt-BR": "",
	"fi-FI": "Neutraali",
	"ja-JP": "変化なし",
};

// Location: SIComponentExportMenu
// Name: INCLUDE_SUBTABLES_OPTION
const includeSubtablesOption = {
	"en-US": 'Main table plus "[PROPERTY]" subtable',
	"en-GB": 'Main table plus "[PROPERTY]" subtable',
	"da-DK": 'Hovedtabel plus "[PROPERTY]"-undertabel',
	"sv-SE": "Huvudtabell och [PROPERTY]-undertabell",
	"de-DE": 'Haupttabelle plus "[PROPERTY]"-Untertabelle',
	"nb-NO": 'Hovedtabell og undertabellen "[PROPERTY]"',
	"fr-FR": 'Tableau principal plus sous-table "[PROPERTY]"',
	"es-ES": 'La tabla principal y la subtabla "[PROPERTY]"',
	"it-IT": 'Tabella principale più tabella secondaria "[PROPERTY]"',
	"nl-NL": 'Alleen hoofdtabel plus "[PROPERTY]" subtabel',
	"pt-BR": 'Tabela principal mais subtabela "[PROPERTY]"',
	"fi-FI": "Päätaulukko plus [PROPERTY]-alitaulukko",
	"ja-JP": "メインテーブルと「[PROPERTY]」サブテーブル",
};

// Location: SIComponentExportMenu
// Name: ALL_PAGES_TAGGED
const allPagesTagged = {
	"en-US": "All pages ([NUMBER_OF_PAGES] pages, [NUMBER_OF_ROWS] rows)",
	"en-GB": "All pages ([NUMBER_OF_PAGES] pages, [NUMBER_OF_ROWS] rows)",
	"da-DK": "Alle sider ([NUMBER_OF_PAGES] sider, [NUMBER_OF_ROWS] rækker)",
	"sv-SE": "Alla sidor ([NUMBER_OF_PAGES] sidor, [NUMBER_OF_ROWS] rader)",
	"de-DE": "Alle Seiten ([NUMBER_OF_PAGES] Seiten, [NUMBER_OF_ROWS] Zeilen)",
	"nb-NO": "Alle sider ([NUMBER_OF_PAGES] sider, [NUMBER_OF_ROWS] rader)",
	"fr-FR": "Toutes les pages ([NUMBER_OF_PAGES] pages, [NUMBER_OF_ROWS] lignes)",
	"es-ES": "Todas las páginas ([NUMBER_OF_PAGES] páginas, [NUMBER_OF_ROWS] filas)",
	"it-IT": "Tutte le pagine ([NUMBER_OF_PAGES] pagine, [NUMBER_OF_ROWS] righe)",
	"nl-NL": "Alle pagina's ([NUMBER_OF_PAGES] pagina's, [NUMBER_OF_ROWS] rijen)",
	"pt-BR": "Todas as páginas ([NUMBER_OF_PAGES] páginas, [NUMBER_OF_ROWS] linhas)",
	"fi-FI": "Kaikki sivut ([NUMBER_OF_PAGES] sivua, [NUMBER_OF_ROWS] riviä)",
	"ja-JP": "すべてのページ（[NUMBER_OF_PAGES]ページ、[NUMBER_OF_ROWS]行）",
};

// Location: SIComponentExportMenu
// Name: VISIBLE_PAGE
const visiblePage = {
	"en-US": "Current page only ([NUMBER_OF_ROWS] rows)",
	"en-GB": "Current page only ([NUMBER_OF_ROWS] rows)",
	"da-DK": "Kun aktuel side ([NUMBER_OF_ROWS] rækker)",
	"sv-SE": "Endast aktuell sida ([NUMBER_OF_ROWS] rader)",
	"de-DE": "Nur aktuelle Seite ([NUMBER_OF_ROWS] Reihen)",
	"nb-NO": "Bare gjeldende side ([NUMBER_OF_ROWS] rader)",
	"fr-FR": "Page actuelle uniquement ([NUMBER_OF_ROWS] lignes)",
	"es-ES": "Solo la página actual ([NUMBER_OF_ROWS] filas)",
	"it-IT": "Solo pagina corrente ([NUMBER_OF_ROWS] righe)",
	"nl-NL": "Alleen huidige pagina ([NUMBER_OF_ROWS] rijen)",
	"pt-BR": "Somente a página atual ([NUMBER_OF_ROWS] linhas)",
	"fi-FI": "Vain nykyinen sivu ([NUMBER_OF_ROWS] riviä)",
	"ja-JP": "現在のページのみ（[NUMBER_OF_ROWS]行）",
};

// Location: SIComponentExportMenu
// Name: PAGES
const pages = {
	"en-US": "Pages",
	"en-GB": "Pages",
	"da-DK": "Sider",
	"sv-SE": "Sidor",
	"de-DE": "Seiten",
	"nb-NO": "Sider",
	"fr-FR": "Pages",
	"es-ES": "Páginas",
	"it-IT": "Pagine",
	"nl-NL": "Pagina's",
	"pt-BR": "Páginas",
	"fi-FI": "Sivut",
	"ja-JP": "ページ",
};

// Location: SIComponentExportMenu
// Name: EXPORT
const exportLabel = {
	"en-US": "Export",
	"en-GB": "Export",
	"da-DK": "Eksport",
	"sv-SE": "Exportera",
	"de-DE": "Export",
	"nb-NO": "Eksporter",
	"fr-FR": "Exporter",
	"es-ES": "Exportar",
	"it-IT": "Esporta",
	"nl-NL": "Exporteren",
	"pt-BR": "",
	"fi-FI": "Vie",
	"ja-JP": "出力",
};

// Location: GDPR_TRACKED_SEARCHTERM_DETAILS
// Name: EXPORT_TO_CSV
const exportToCsv = {
	"en-US": "Export to CSV",
	"en-GB": "Export to CSV",
	"da-DK": "Eksportér til CSV",
	"sv-SE": "Exportera till CSV",
	"de-DE": "Nach CSV exportieren",
	"nb-NO": "Eksporter til CSV",
	"fr-FR": "Exporter au format CSV",
	"es-ES": "Exportar a CSV",
	"it-IT": "Esporta in CSV",
	"nl-NL": "Exporteren naar CSV",
	"pt-BR": "",
	"fi-FI": "Vie CSV-muotoon",
	"ja-JP": "CSVにエクスポート",
};

// Location: SIComponentExportMenu
// Name: INCLUDE_SUBTABLES
const includeSubtables = {
	"en-US": "Data",
	"en-GB": "Data",
	"da-DK": "Data",
	"sv-SE": "Data",
	"de-DE": "Daten",
	"nb-NO": "Data",
	"fr-FR": "Données",
	"es-ES": "Datos",
	"it-IT": "Dati",
	"nl-NL": "Data",
	"pt-BR": "Dados",
	"fi-FI": "Tiedot",
	"ja-JP": "データ",
};

// Location: SIComponentExportMenu
// Name: DONT_INCLUDE_SUBTABLES
const dontIncludeSubtables = {
	"en-US": "Main table only",
	"en-GB": "Main table only",
	"da-DK": "Kun hovedtabel",
	"sv-SE": "Endast huvudtabell",
	"de-DE": "Nur Haupttabelle",
	"nb-NO": "Bare hovedtabell",
	"fr-FR": "Tableau principal uniquement",
	"es-ES": "Solo la tabla principal",
	"it-IT": "Solo tabella principale",
	"nl-NL": "Alleen hoofdtabel",
	"pt-BR": "Somente a tabela principal",
	"fi-FI": "Vain päätaulukko",
	"ja-JP": "メインテーブルのみ",
};

// TODO create translation key on server side
const nextMonth = {
	"en-US": "Next month",
	"en-GB": "Next month",
	"da-DK": "",
	"sv-SE": "",
	"de-DE": "",
	"nb-NO": "",
	"fr-FR": "",
	"es-ES": "",
	"it-IT": "",
	"nl-NL": "",
	"pt-BR": "",
	"fi-FI": "",
	"ja-JP": "",
};

// Location: PeriodPicker
// Name: NEXT_YEAR
const nextYear = {
	"en-US": "Next year",
	"en-GB": "Next year",
	"da-DK": "Næste år",
	"sv-SE": "Nästa år",
	"de-DE": "Nächstes Jahr",
	"nb-NO": "Neste år",
	"fr-FR": "Année suivante",
	"es-ES": "Próximo año",
	"it-IT": "Anno prossimo",
	"nl-NL": "Volgend jaar",
	"pt-BR": "Próximo ano",
	"fi-FI": "Seuraava vuosi",
	"ja-JP": "来年",
};

// Location: PeriodPicker
// Name: PREVIOUS_YEAR_ARIA_LABEL
const previousYear = {
	"en-US": "Previous year",
	"en-GB": "Previous year",
	"da-DK": "Forrige år",
	"sv-SE": "Tidigare år",
	"de-DE": "Vorheriges Jahr",
	"nb-NO": "Forrige år",
	"fr-FR": "Année précédente",
	"es-ES": "Año anterior",
	"it-IT": "Anno precedente",
	"nl-NL": "Vorig jaar ",
	"pt-BR": "Ano anterior",
	"fi-FI": "Edellinen vuosi",
	"ja-JP": "前年",
};

// TODO create translation key on server side
const nextCentury = {
	"en-US": "Next century",
	"en-GB": "Next century",
	"da-DK": "",
	"sv-SE": "",
	"de-DE": "",
	"nb-NO": "",
	"fr-FR": "",
	"es-ES": "",
	"it-IT": "",
	"nl-NL": "",
	"pt-BR": "",
	"fi-FI": "",
	"ja-JP": "",
};

// TODO create translation key on server side
const nextDecade = {
	"en-US": "Next decade",
	"en-GB": "Next decade",
	"da-DK": "",
	"sv-SE": "",
	"de-DE": "",
	"nb-NO": "",
	"fr-FR": "",
	"es-ES": "",
	"it-IT": "",
	"nl-NL": "",
	"pt-BR": "",
	"fi-FI": "",
	"ja-JP": "",
};

// TODO create translation key on server side
const prevMonth = {
	"en-US": "Previous month",
	"en-GB": "Previous month",
	"da-DK": "",
	"sv-SE": "",
	"de-DE": "",
	"nb-NO": "",
	"fr-FR": "",
	"es-ES": "",
	"it-IT": "",
	"nl-NL": "",
	"pt-BR": "",
	"fi-FI": "",
	"ja-JP": "",
};

// TODO create translation key on server side
const prevYear = {
	"en-US": "Previous year",
	"en-GB": "Previous year",
	"da-DK": "",
	"sv-SE": "",
	"de-DE": "",
	"nb-NO": "",
	"fr-FR": "",
	"es-ES": "",
	"it-IT": "",
	"nl-NL": "",
	"pt-BR": "",
	"fi-FI": "",
	"ja-JP": "",
};

// TODO create translation key on server side
const prevCentury = {
	"en-US": "Previous century",
	"en-GB": "Previous century",
	"da-DK": "",
	"sv-SE": "",
	"de-DE": "",
	"nb-NO": "",
	"fr-FR": "",
	"es-ES": "",
	"it-IT": "",
	"nl-NL": "",
	"pt-BR": "",
	"fi-FI": "",
	"ja-JP": "",
};

// TODO create translation key on server side
const prevDecade = {
	"en-US": "Previous decade",
	"en-GB": "Previous decade",
	"da-DK": "",
	"sv-SE": "",
	"de-DE": "",
	"nb-NO": "",
	"fr-FR": "",
	"es-ES": "",
	"it-IT": "",
	"nl-NL": "",
	"pt-BR": "",
	"fi-FI": "",
	"ja-JP": "",
};

// TODO create translation key on server side
const selectDate = {
	"en-US": "Select a date",
	"en-GB": "Select a date",
	"da-DK": "",
	"sv-SE": "",
	"de-DE": "",
	"nb-NO": "",
	"fr-FR": "",
	"es-ES": "",
	"it-IT": "",
	"nl-NL": "",
	"pt-BR": "",
	"fi-FI": "",
	"ja-JP": "",
};

// TODO create translation key on server side
const selectDateFor = {
	"en-US": "Select a date for X",
	"en-GB": "Select a date for X",
	"da-DK": "",
	"sv-SE": "",
	"de-DE": "",
	"nb-NO": "",
	"fr-FR": "",
	"es-ES": "",
	"it-IT": "",
	"nl-NL": "",
	"pt-BR": "",
	"fi-FI": "",
	"ja-JP": "",
};

// TODO create translation key on server side
const invalidDate = {
	"en-US": "The date is invalid!",
	"en-GB": "The date is invalid!",
	"da-DK": "",
	"sv-SE": "",
	"de-DE": "",
	"nb-NO": "",
	"fr-FR": "",
	"es-ES": "",
	"it-IT": "",
	"nl-NL": "",
	"pt-BR": "",
	"fi-FI": "",
	"ja-JP": "",
};

// TODO create translation key on server side
const dateRangeError = {
	"en-US": "This date is outside of the possible date range",
	"en-GB": "This date is outside of the possible date range",
	"da-DK": "",
	"sv-SE": "",
	"de-DE": "",
	"nb-NO": "",
	"fr-FR": "",
	"es-ES": "",
	"it-IT": "",
	"nl-NL": "",
	"pt-BR": "",
	"fi-FI": "",
	"ja-JP": "",
};

// Location: GENERAL_CONTENT_SWITCHER_TEXTS
// Name: DEFAULT_ARIA_LABEL
// TODO Download and add translated translation keys
const contentSwitcher = {
	"en-US": "Content switcher",
	"en-GB": "Content switcher",
	"da-DK": "",
	"sv-SE": "",
	"de-DE": "",
	"nb-NO": "",
	"fr-FR": "",
	"es-ES": "",
	"it-IT": "",
	"nl-NL": "",
	"pt-BR": "",
	"fi-FI": "",
	"ja-JP": "",
};

// Location: APPLICATION
// Name: APPLY_BUTTON
const apply = {
	"en-US": "Apply",
	"en-GB": "Apply",
	"da-DK": "Anvend",
	"sv-SE": "Tillämpa",
	"de-DE": "Anwenden",
	"nb-NO": "Bruk",
	"fr-FR": "Appliquer",
	"es-ES": "Aplicar",
	"it-IT": "Applica",
	"nl-NL": "Toepassen",
	"pt-BR": "Aplicar",
	"fi-FI": "Käytä",
	"ja-JP": "適用",
};

// Location: APPLICATION
// Name: CLEAR_ALL
const clearAll = {
	"en-US": "Clear all",
	"en-GB": "Clear all",
	"da-DK": "Ryd alle",
	"sv-SE": "Radera alla",
	"de-DE": "Alles löschen",
	"nb-NO": "Fjern alle",
	"fr-FR": "Tout effacer",
	"es-ES": "Borrar todo",
	"it-IT": "Cancella tutto",
	"nl-NL": "Alles wissen",
	"pt-BR": "",
	"fi-FI": "Poista kaikki",
	"ja-JP": "すべてクリア",
};

// Location: APPLICATION
// Name: FILTER
const filter = {
	"en-US": "Filter",
	"en-GB": "Filter",
	"da-DK": "Filtrer",
	"sv-SE": "Filtrera",
	"de-DE": "Filtern",
	"nb-NO": "Filtrer",
	"fr-FR": "Filtrer",
	"es-ES": "Filtrar",
	"it-IT": "Filtra",
	"nl-NL": "Filteren",
	"pt-BR": "",
	"fi-FI": "Suodata",
	"ja-JP": "フィルター",
};

// Location: APPLICATION
// Name: FILTERS_TEXT
const filters = {
	"en-US": "Filters",
	"en-GB": "Filters",
	"da-DK": "Filtre",
	"sv-SE": "Filter",
	"de-DE": "Filter",
	"nb-NO": "Filtre",
	"fr-FR": "Filtres",
	"es-ES": "Filtros",
	"it-IT": "Filtri",
	"nl-NL": "Filters",
	"pt-BR": "",
	"fi-FI": "Suodattimet",
	"ja-JP": "フィルター",
};

// Location: APPLICATION
// Name: FILTER_BY
const filterBy = {
	"en-US": "Filter by",
	"en-GB": "Filter by",
	"da-DK": "Filtrer efter",
	"sv-SE": "Filtrera efter",
	"de-DE": "Filtern nach",
	"nb-NO": "Filtrer etter",
	"fr-FR": "Filtrer par",
	"es-ES": "Filtrar por",
	"it-IT": "Filtra per",
	"nl-NL": "Filteren op:",
	"pt-BR": "",
	"fi-FI": "Suodatusperuste",
	"ja-JP": "フィルター基準",
};

// Location: APPLICATION
// Name: CLICK_TO_REMOVE
const clickToRemove = {
	"en-US": "click to remove",
	"en-GB": "click to remove",
	"da-DK": "",
	"sv-SE": "",
	"de-DE": "",
	"nb-NO": "",
	"fr-FR": "",
	"es-ES": "",
	"it-IT": "",
	"nl-NL": "",
	"pt-BR": "",
	"fi-FI": "",
	"ja-JP": "",
};

// Location: APPLICATION
// Name: ACTIVE_FILTERS
const activeFilters = {
	"en-US": "Active filters",
	"en-GB": "Active filters",
	"da-DK": "",
	"sv-SE": "",
	"de-DE": "",
	"nb-NO": "",
	"fr-FR": "",
	"es-ES": "",
	"it-IT": "",
	"nl-NL": "",
	"pt-BR": "",
	"fi-FI": "",
	"ja-JP": "",
};

// Location: PeriodPicker
// Name: NOW
const now = {
	"en-US": "Now",
	"en-GB": "Now",
	"da-DK": "Nu",
	"sv-SE": "Nu",
	"de-DE": "Jetzt",
	"nb-NO": "Nå",
	"fr-FR": "Maintenant",
	"es-ES": "Ahora",
	"it-IT": "Adesso",
	"nl-NL": "Nu",
	"pt-BR": "Agora",
	"fi-FI": "Nyt",
	"ja-JP": "今すぐ",
};

// Location: PeriodPicker
// Name: TODAY
const today = {
	"en-US": "Today",
	"en-GB": "Today",
	"da-DK": "I dag",
	"sv-SE": "Idag",
	"de-DE": "Heute",
	"nb-NO": "I dag",
	"fr-FR": "Aujourd'hui",
	"es-ES": "Hoy",
	"it-IT": "Oggi",
	"nl-NL": "Vandaag",
	"pt-BR": "Hoje",
	"fi-FI": "Tänään",
	"ja-JP": "今日",
};

// Location: PeriodPicker
// Name: YESTERDAY
const yesterday = {
	"en-US": "Yesterday",
	"en-GB": "Yesterday",
	"da-DK": "I går",
	"sv-SE": "Igår",
	"de-DE": "Gestern",
	"nb-NO": "I går",
	"fr-FR": "Hier",
	"es-ES": "Ayer",
	"it-IT": "Ieri",
	"nl-NL": "Gisteren",
	"pt-BR": "Ontem",
	"fi-FI": "Eilen",
	"ja-JP": "昨日",
};

// Location: PeriodPicker
// Name: LAST_7_DAYS
const last7Days = {
	"en-US": "Last 7 days",
	"en-GB": "Last 7 days",
	"da-DK": "Sidste 7 dage",
	"sv-SE": "Senaste 7 dagarna",
	"de-DE": "Letzte 7 Tage",
	"nb-NO": "Siste 7 dager",
	"fr-FR": "Derniers 7 jours",
	"es-ES": "Últimos 7 días",
	"it-IT": "Ultimi 7 giorni",
	"nl-NL": "Afgelopen 7 dagen",
	"pt-BR": "Últimos 7 dias",
	"fi-FI": "Viimeiset 7 päivää",
	"ja-JP": "過去7日間",
};

// Location: PeriodPicker
// Name: LAST_14_DAYS
const last14Days = {
	"en-US": "Last 14 days",
	"en-GB": "Last 14 days",
	"da-DK": "De sidste 14 dage",
	"sv-SE": "Senaste 14 dagarna",
	"de-DE": "Letzte 14 Tage",
	"nb-NO": "De siste 14 dagene",
	"fr-FR": "14 derniers jours",
	"es-ES": "14 últimos días",
	"it-IT": "Ultimi 14 giorni",
	"nl-NL": "Afgelopen 14 dagen",
	"pt-BR": "Últimos 14 dias",
	"fi-FI": "Viimeiset 14 päivää",
	"ja-JP": "過去14日間",
};

// Location: PeriodPicker
// Name: LAST_30_DAYS
const last30Days = {
	"en-US": "Last 30 days",
	"en-GB": "Last 30 days",
	"da-DK": "De sidste 30 dage",
	"sv-SE": "Senaste 30 dagarna",
	"de-DE": "Letzte 30 Tage",
	"nb-NO": "De siste 30 dagene",
	"fr-FR": "30 derniers jours",
	"es-ES": "Últimos 30 días",
	"it-IT": "Ultimi 30 giorni",
	"nl-NL": "Afgelopen 30 dagen",
	"pt-BR": "Últimos 30 dias",
	"fi-FI": "Viimeiset 30 päivää",
	"ja-JP": "過去30日間",
};

// Location: PeriodPicker
// Name: LAST_365_DAYS
const last365Days = {
	"en-US": "Last 365 days",
	"en-GB": "Last 365 days",
	"da-DK": "De sidste 365 dage",
	"sv-SE": "Senaste 365 dagarna",
	"de-DE": "Letzte 365 Tage",
	"nb-NO": "De siste 365 dagene",
	"fr-FR": "365 derniers jours",
	"es-ES": "Últimos 365 días",
	"it-IT": "Ultimi 365 giorni",
	"nl-NL": "Afgelopen 365 dagen",
	"pt-BR": "Últimos 365 dias",
	"fi-FI": "Viimeiset 365 päivää",
	"ja-JP": "直近365日",
};

// Location: PeriodPicker
// Name: LAST_WEEK
const lastWeek = {
	"en-US": "Last week",
	"en-GB": "Last week",
	"da-DK": "Sidste uge",
	"sv-SE": "Föregående vecka",
	"de-DE": "Letzte Woche",
	"nb-NO": "Forrige uke",
	"fr-FR": "Semaine dernière",
	"es-ES": "La semana pasada",
	"it-IT": "Settimana scorsa",
	"nl-NL": "Afgelopen week",
	"pt-BR": "Última semana",
	"fi-FI": "Viime viikko",
	"ja-JP": "先週",
};

// Location: PeriodPicker
// Name: THIS_MONTH
const thisMonth = {
	"en-US": "This month",
	"en-GB": "This month",
	"da-DK": "Denne måned",
	"sv-SE": "Nuvarande månad",
	"de-DE": "Dieser Monat",
	"nb-NO": "",
	"fr-FR": "Ce mois-ci",
	"es-ES": "",
	"it-IT": "Questo mese",
	"nl-NL": "Deze maand",
	"pt-BR": "Este mês",
	"fi-FI": "Tämä kuukausi",
	"ja-JP": "今月",
};

// Location: PeriodPicker
// Name: LAST_MONTH
const lastMonth = {
	"en-US": "Last month",
	"en-GB": "Last month",
	"da-DK": "Sidste måned",
	"sv-SE": "Senaste månaden",
	"de-DE": "Letzten Monat",
	"nb-NO": "Forrige måned",
	"fr-FR": "Mois dernier",
	"es-ES": "Mes pasado",
	"it-IT": "Mese scorso",
	"nl-NL": "Afgelopen maand",
	"pt-BR": "Último mês",
	"fi-FI": "Viime kuukausi",
	"ja-JP": "先月",
};

// Location: PeriodPicker
// Name: THIS_QUARTER
const thisQuarter = {
	"en-US": "This quarter",
	"en-GB": "This quarter",
	"da-DK": "Dette kvartal",
	"sv-SE": "Detta kvartal",
	"de-DE": "Dieses Quartal",
	"nb-NO": "Dette kvartalet",
	"fr-FR": "Ce trimestre",
	"es-ES": "Este trimestre",
	"it-IT": "Questo trimestre",
	"nl-NL": "Dit kwartaal",
	"pt-BR": "Este trimestre",
	"fi-FI": "Tämä vuosineljännes",
	"ja-JP": "この四半期",
};

// Location: PeriodPicker
// Name: LAST_QUARTER
const lastQuarter = {
	"en-US": "Last quarter",
	"en-GB": "Last quarter",
	"da-DK": "Sidste kvartal",
	"sv-SE": "Senaste kvartalet",
	"de-DE": "Letztes Vierteljahr",
	"nb-NO": "Siste kvartal",
	"fr-FR": "Dernier trimestre",
	"es-ES": "Último trimestre",
	"it-IT": "Ultimo trimestre",
	"nl-NL": "Afgelopen kwartaal",
	"pt-BR": "Último trimestre",
	"fi-FI": "Edellinen neljännes",
	"ja-JP": "前四半期",
};

// Location: PeriodPicker
// Name: LAST_6_MONTHS
const last6Months = {
	"en-US": "Last 6 months",
	"en-GB": "Last 6 months",
	"da-DK": "Sidste 6 måneder",
	"sv-SE": "Senaste 6 månaderna",
	"de-DE": "In den letzten 6 Monaten",
	"nb-NO": "De siste seks månedene",
	"fr-FR": "6 derniers mois",
	"es-ES": "6 últimos meses",
	"it-IT": "Ultimi 6 mesi",
	"nl-NL": "Afgelopen 6 maanden",
	"pt-BR": "Últimos 6 meses",
	"fi-FI": "Viimeiset 6 kuukautta",
	"ja-JP": "直近6か月",
};

// Location: PeriodPicker
// Name: THIS_YEAR
const thisYear = {
	"en-US": "This year",
	"en-GB": "This year",
	"da-DK": "Dette år",
	"sv-SE": "Detta år",
	"de-DE": "Dieses Jahr",
	"nb-NO": "Dette året",
	"fr-FR": "Cette année",
	"es-ES": "Este año",
	"it-IT": "Quest'anno",
	"nl-NL": "Dit jaar",
	"pt-BR": "Este ano",
	"fi-FI": "Tämä vuosi",
	"ja-JP": "今年",
};

// Location: PeriodPicker
// Name: LAST_YEAR
const lastYear = {
	"en-US": "Last year",
	"en-GB": "Last year",
	"da-DK": "Sidste år",
	"sv-SE": "Föregående år",
	"de-DE": "Letztes Jahr",
	"nb-NO": "I fjor",
	"fr-FR": "Année précédente",
	"es-ES": "El año pasado",
	"it-IT": "Lo scorso anno",
	"nl-NL": "Vorig jaar",
	"pt-BR": "Ano passado",
	"fi-FI": "Viime vuosi",
	"ja-JP": "昨年",
};

// Location: PeriodPicker
// Name: CUSTOM_DATE
const customDate = {
	"en-US": "Custom",
	"en-GB": "Custom",
	"da-DK": "Brugerdefineret",
	"sv-SE": "Anpassad",
	"de-DE": "Benutzerdefiniert",
	"nb-NO": "Tilpasset",
	"fr-FR": "Personnalisation",
	"es-ES": "Personalizado",
	"it-IT": "Personalizzato",
	"nl-NL": "Custom",
	"pt-BR": "Personalizar",
	"fi-FI": "Muokattu",
	"ja-JP": "カスタム",
};

// Location: PeriodPicker
// Name: QUARTER_1
const quarter1 = {
	"en-US": "Q1",
	"en-GB": "Q1",
	"da-DK": "KV1",
	"sv-SE": "Första kvartalet",
	"de-DE": "1. Quartal",
	"nb-NO": "1. kvartal",
	"fr-FR": "Q1",
	"es-ES": "Q1",
	"it-IT": "Q1",
	"nl-NL": "Q1",
	"pt-BR": "Q1",
	"fi-FI": "K1",
	"ja-JP": "第1四半期",
};

// Location: PeriodPicker
// Name: QUARTER_2
const quarter2 = {
	"en-US": "Q2",
	"en-GB": "Q2",
	"da-DK": "KV2",
	"sv-SE": "Andra kvartalet",
	"de-DE": "2. Quartal",
	"nb-NO": "2. kvartal",
	"fr-FR": "Q2",
	"es-ES": "Q2",
	"it-IT": "Q2",
	"nl-NL": "Q2",
	"pt-BR": "Q2",
	"fi-FI": "K2",
	"ja-JP": "第2四半期",
};

// Location: PeriodPicker
// Name: QUARTER_3
const quarter3 = {
	"en-US": "Q3",
	"en-GB": "Q3",
	"da-DK": "KV3",
	"sv-SE": "Tredje kvartalet",
	"de-DE": "3. Quartal",
	"nb-NO": "3. kvartal",
	"fr-FR": "Q3",
	"es-ES": "Q3",
	"it-IT": "Q3",
	"nl-NL": "Q3",
	"pt-BR": "Q3",
	"fi-FI": "K3",
	"ja-JP": "第3四半期",
};

// Location: PeriodPicker
// Name: QUARTER_4
const quarter4 = {
	"en-US": "Q4",
	"en-GB": "Q4",
	"da-DK": "KV4",
	"sv-SE": "Fjärde kvartalet",
	"de-DE": "4. Quartal",
	"nb-NO": "4. kvartal",
	"fr-FR": "Q4",
	"es-ES": "Q4",
	"it-IT": "Q4",
	"nl-NL": "Q4",
	"pt-BR": "Q4",
	"fi-FI": "K4",
	"ja-JP": "第4四半期",
};

// Location: PeriodPicker
// Name: PERIOD
const period = {
	"en-US": "Date range",
	"en-GB": "Date range",
	"da-DK": "Datointerval",
	"sv-SE": "Datumintervall",
	"de-DE": "Datumsbereich",
	"nb-NO": "Datoperiode",
	"fr-FR": "Plage de dates",
	"es-ES": "Intervalo de fechas",
	"it-IT": "Intervallo date",
	"nl-NL": "datumbereik",
	"pt-BR": "Intervalo de datas",
	"fi-FI": "Päivämääräalue",
	"ja-JP": "日付範囲",
};

// Location: controls/datepicker
// Name: Clear
const clear = {
	"en-US": "Clear",
	"en-GB": "Clear",
	"da-DK": "Fjern filter",
	"sv-SE": "Ta bort",
	"de-DE": "Entfernen",
	"nb-NO": "Slett",
	"fr-FR": "Supprimer",
	"es-ES": "Borrar",
	"it-IT": "Cancella",
	"nl-NL": "Wissen",
	"pt-BR": "Limpar",
	"fi-FI": "Tyhjennä",
	"ja-JP": "クリア",
};

// Location: SideNavigation
// name: SearchInNavigationLabel
const searchInNavigationLabel = {
	"en-US": "Search",
	"en-GB": "Search",
	"da-DK": "Søg",
	"sv-SE": "Sök",
	"de-DE": "Suchen",
	"nb-NO": "Søk",
	"fr-FR": "Rechercher",
	"es-ES": "Buscar",
	"it-IT": "Cerca",
	"nl-NL": "Zoeken",
	"pt-BR": "",
	"fi-FI": "Haku",
	"ja-JP": "検索",
};

// Location: SideNavigation
// name: SearchInNavigationPlaceholder
const searchInNavigationPlaceholder = {
	"en-US": "Search in navigation",
	"en-GB": "Search in navigation",
	"da-DK": "Søg i navigation",
	"sv-SE": "Sök i navigering",
	"de-DE": "In der Navigation suchen",
	"nb-NO": "Søk i navigasjon",
	"fr-FR": "Rechercher dans la navigation",
	"es-ES": "Buscar en navegación",
	"it-IT": "Cerca nella navigazione",
	"nl-NL": "Zoeken in navigatie",
	"pt-BR": "",
	"fi-FI": "Hae navigoinnista",
	"ja-JP": "ナビゲーションで検索",
};

// Location: SideNavigation
// Name: CollapseNavigation
const collapseNavigation = {
	"en-US": "Collapse navigation",
	"en-GB": "Collapse navigation",
	"da-DK": "Skjul navigation",
	"sv-SE": "Dölj navigeringen",
	"de-DE": "Navigation reduzieren",
	"nb-NO": "Skjul navigasjonen",
	"fr-FR": "Réduire la navigation",
	"es-ES": "Contraer navegación",
	"it-IT": "Comprimi l'area di navigazione",
	"nl-NL": "Navigatie samenvouwen",
	"pt-BR": "",
	"fi-FI": "Pienennä navigointi",
	"ja-JP": "ナビゲーションを折りたたむ",
};

// Location: SideNavigation
// Name: ExpandNavigation
const expandNavigation = {
	"en-US": "Expand navigation",
	"en-GB": "Expand navigation",
	"da-DK": "Udvid navigation",
	"sv-SE": "Expandera navigeringen",
	"de-DE": "Navigation erweitern",
	"nb-NO": "Utvid navigasjonen",
	"fr-FR": "Développer la navigation",
	"es-ES": "Expandir navegación",
	"it-IT": "Espandi l'area di navigazione",
	"nl-NL": "Navigatie uitbreiden",
	"pt-BR": "",
	"fi-FI": "Suurenna navigointi",
	"ja-JP": "ナビゲーションを広げる",
};

// Location: SideNavigation
// Name: BackToMainNavigation
const backToMainNavigation = {
	"en-US": "Back to main navigation",
	"en-GB": "Back to main navigation",
	"da-DK": "Tilbage til hovednavigation",
	"sv-SE": "Tillbaka till huvudnavigeringen",
	"de-DE": "Zurück zur Hauptnavigation",
	"nb-NO": "Tilbake til hovednavigasjonen",
	"fr-FR": "Retour à la navigation principale",
	"es-ES": "Volver a la navegación principal",
	"it-IT": "Torna all'area di navigazione principale",
	"nl-NL": "Terug naar hoofdnavigatie",
	"pt-BR": "",
	"fi-FI": "Takaisin päänavigointiin",
	"ja-JP": "メインナビゲーションに戻る",
};

// Location: si.controls
// Name: PROFILE
const profile = {
	"en-US": "Profile",
	"en-GB": "Profile",
	"da-DK": "Profil",
	"sv-SE": "Profil",
	"de-DE": "Profil",
	"nb-NO": "Profil",
	"fr-FR": "Profil",
	"es-ES": "Perfil",
	"it-IT": "Profilo",
	"nl-NL": "Profiel",
	"pt-BR": "Perfil",
	"fi-FI": "Profiili",
	"ja-JP": "プロフィール",
};

// Location: APPLICATION
// Name: SKIP_TO_CONTENT_LINK
const skipToContent = {
	"en-US": "Skip to main content",
	"en-GB": "Skip to main content",
	"da-DK": "Gå til hovedindhold",
	"sv-SE": "Gå till det huvudsakliga innehållet",
	"de-DE": "Zum Hauptinhalt springen",
	"nb-NO": "Gå til hovedinnhold",
	"fr-FR": "Accéder au contenu principal",
	"es-ES": "Ir al contenido principal",
	"it-IT": "Passa al contenuto principale",
	"nl-NL": "Naar hoofdinhoud",
	"pt-BR": "Ir para o conteúdo principal",
	"fi-FI": "Siirry pääsisältöön",
	"ja-JP": "メインコンテンツに移動",
};

// Location: PeriodPicker
// Name: PRESETS
const presets = {
	"en-US": "Presets",
	"en-GB": "Presets",
	"da-DK": "Forudindstillinger",
	"sv-SE": "Förinställda datum",
	"de-DE": "Voreinstellungen",
	"nb-NO": "Forhåndsdefinerte",
	"fr-FR": "Paramètres prédéfinis",
	"es-ES": "Valores preestablecidos",
	"it-IT": "Preimpostazioni",
	"nl-NL": "Voorinstellingen",
	"pt-BR": "Predefinições",
	"fi-FI": "Esiasetukset",
	"ja-JP": "プリセット",
};

// Location: PeriodPicker
// Name: YEAR_OVERVIEW
const yearOverview = {
	"en-US": "Year overview",
	"en-GB": "Year overview",
	"da-DK": "Årlig opsummering",
	"sv-SE": "Årsöversikt",
	"de-DE": "Jahresübersicht",
	"nb-NO": "Årsoversikt",
	"fr-FR": "Aperçu sur l'année",
	"es-ES": "Información general del año",
	"it-IT": "Panoramica anno",
	"nl-NL": "Jaaroverzicht",
	"pt-BR": "Visão geral do ano",
	"fi-FI": "Vuosiyhteenveto",
	"ja-JP": "年間一覧",
};

// Location: FANCYLIB
// Name: FORMATTED_NUMBER_MISSING
const numberMissing = {
	"en-US": "No number available",
	"en-GB": "No number available",
	"da-DK": "Intet tal tilgængeligt",
	"sv-SE": "Inget tal tillgängligt",
	"de-DE": "Keine Nummer verfügbar",
	"nb-NO": "Ingen tall tilgjengelig",
	"fr-FR": "Numéro non disponible",
	"es-ES": "Número no disponible",
	"it-IT": "Numero non disponibile",
	"nl-NL": "Nummer niet beschikbaar",
	"pt-BR": "Número não disponível",
	"fi-FI": "Numero ei saatavilla",
	"ja-JP": "利用可能な番号はありません",
};

// Location: FANCYLIB
// Name: FORMATTED_DATE_MISSING
const dateMissing = {
	"en-US": "Unknown date",
	"en-GB": "Unknown date",
	"da-DK": "Ukendt dato",
	"sv-SE": "Okänt datum",
	"de-DE": "Unbekanntes Datum",
	"nb-NO": "Ukjent dato",
	"fr-FR": "Date inconnue",
	"es-ES": "Fecha desconocida",
	"it-IT": "Data sconosciuta",
	"nl-NL": "Onbekende datum",
	"pt-BR": "Data desconhecida",
	"fi-FI": "Tuntematon päivämäärä",
	"ja-JP": "不明な日付",
};

const keys = {
	create,
	directionDown,
	directionUp,
	directionNeutral,
	messageNeutral,
	messagePositive,
	messageWarning,
	messageCritical,
	messageNegative,
	noMatches,
	search,
	skipChart,
	noAnnotationsForThisChart,
	createAnnotationHelpText,
	noAnnotationsForChartPeriod,
	noAnnotationsForFavorite,
	noAnnotationsHelpTextForFavorite,
	annotations,
	annotationsDate,
	annotationsComment,
	annotationsCreatedBy,
	annotationsVisibility,
	allAnnotations,
	chartPeriodAnnotations,
	favoriteAnnotations,
	favoriteAnnotation,
	showAnnotations,
	showAnnotationsSrOnly,
	favorite,
	newAnnotation,
	annotationsShowFilter,
	createAnnotation,
	editAnnotation,
	saveAnnotation,
	edit,
	closeAnnotations,
	annotationsVisibilityField,
	annotationsCommentField,
	annotationsVisibleInField,
	annotationsSystemNotificationHelpText,
	annotationsGlobalVisibilityTooltip,
	everyone,
	onlyMe,
	skipTable,
	tableToolbarLabel,
	tableNoDataStateLabel,
	columnHeaderRoleDescription,
	closeDialog,
	sparkLineAriaLabel,
	remove,
	select,
	selected,
	selectedItems,
	selectAll,
	selectAllMatching,
	deselect,
	deselectAll,
	dciScoreGauge,
	industryBenchmark,
	siteTarget,
	max,
	includeSubtablesOption,
	dontIncludeSubtables,
	allPagesTagged,
	visiblePage,
	pages,
	exportLabel,
	exportToCsv,
	includeSubtables,
	nextMonth,
	nextYear,
	previousYear,
	nextCentury,
	nextDecade,
	prevMonth,
	prevYear,
	prevCentury,
	prevDecade,
	selectDate,
	selectDateFor,
	invalidDate,
	dateRangeError,
	contentSwitcher,
	apply,
	clearAll,
	filter,
	filters,
	filterBy,
	clickToRemove,
	activeFilters,
	now,
	today,
	yesterday,
	last7Days,
	last14Days,
	last30Days,
	last365Days,
	lastWeek,
	thisMonth,
	lastMonth,
	thisQuarter,
	lastQuarter,
	last6Months,
	thisYear,
	lastYear,
	customDate,
	quarter1,
	quarter2,
	quarter3,
	quarter4,
	period,
	clear,
	searchInNavigationLabel,
	searchInNavigationPlaceholder,
	collapseNavigation,
	expandNavigation,
	backToMainNavigation,
	profile,
	skipToContent,
	presets,
	yearOverview,
	numberMissing,
	dateMissing,
};

export type TranslationKeys = keyof typeof keys;
export type Language =
	| "en-US"
	| "en-GB"
	| "da-DK"
	| "sv-SE"
	| "de-DE"
	| "nb-NO"
	| "fr-FR"
	| "es-ES"
	| "it-IT"
	| "nl-NL"
	| "pt-BR"
	| "fi-FI"
	| "ja-JP";

const extractLanguage = (lang: Language) =>
	Object.entries(keys).reduce((a, [k, v]) => {
		a[k as TranslationKeys] = v[lang];
		return a;
	}, {} as Record<TranslationKeys, string>);

export const translations: Record<Language, Record<TranslationKeys, string>> = {
	"en-US": extractLanguage("en-US"),
	"en-GB": extractLanguage("en-GB"),
	"da-DK": extractLanguage("da-DK"),
	"sv-SE": extractLanguage("sv-SE"),
	"de-DE": extractLanguage("de-DE"),
	"nb-NO": extractLanguage("nb-NO"),
	"fr-FR": extractLanguage("fr-FR"),
	"es-ES": extractLanguage("es-ES"),
	"it-IT": extractLanguage("it-IT"),
	"nl-NL": extractLanguage("nl-NL"),
	"pt-BR": extractLanguage("pt-BR"),
	"fi-FI": extractLanguage("fi-FI"),
	"ja-JP": extractLanguage("ja-JP"),
};

export function useLabTranslations(): Record<TranslationKeys, string> {
	const { lang, translationOverrides } = React.useContext(getOrCreateGlobalFancyContext());
	if (translationOverrides) {
		return { ...translations[lang], ...translationOverrides };
	} else {
		return translations[lang];
	}
}
