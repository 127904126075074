import * as scss from "./typography.scss";

interface Typography {
	/** Controls vertical alignment */
	alignment: Alignment;
	/** Controls font-size */
	size: Size;
	/** Controls visual tone */
	tone: Tone;
	/** Controls font-weight */
	emphasis: Emphasis;
	/** Controls line-height */
	lineHeight?: "single-line" | "multi-line";
}

type Alignment = "left" | "center" | "right";
type Size = "xSmall" | "small" | "medium" | "large" | "xLarge" | "xxLarge" | "xxxLarge";
type Tone = "neutralDark" | "neutralLight" | "subtle" | "positive" | "negative" | "warning";
type Emphasis = "normal" | "medium" | "strong";

type TypographyOptions = Partial<Typography>;

export type TypographyProps<TInclusions extends keyof TypographyOptions> = {
	/** Custom unique id */
	id?: string;
	/** Content to be displayed */
	children: React.ReactNode | React.ReactNode[];
} & Pick<TypographyOptions, TInclusions>;

export function alignmentClass(value: Alignment): string {
	switch (value) {
		case "left":
			return scss.alignmentLeft;
		case "center":
			return scss.alignmentCenter;
		case "right":
			return scss.alignmentRight;
	}
}

export function sizeClass(value: Size): string {
	switch (value) {
		case "xSmall":
			return scss.sizeXSmall;
		case "small":
			return scss.sizeSmall;
		case "medium":
			return scss.sizeMedium;
		case "large":
			return scss.sizeLarge;
		case "xLarge":
			return scss.sizeXLarge;
		case "xxLarge":
			return scss.sizeXxLarge;
		case "xxxLarge":
			return scss.sizeXxxLarge;
	}
}

export function toneClass(value: Tone): string {
	switch (value) {
		case "neutralDark":
			return scss.toneNeutralDark;
		case "neutralLight":
			return scss.toneNeutralLight;
		case "subtle":
			return scss.toneSubtle;
		case "positive":
			return scss.tonePositive;
		case "negative":
			return scss.toneNegative;
		case "warning":
			return scss.toneWarning;
	}
}

export function emphasisClass(value: Emphasis): string {
	switch (value) {
		case "normal":
			return scss.emphasisNormal;
		case "medium":
			return scss.emphasisMedium;
		case "strong":
			return scss.emphasisStrong;
	}
}

export const lineHeightSingleLineClass = scss.lineHeightSingleLine;
