import React, { useState, ReactNode, useEffect, useRef } from "react";
import { navigate, useStaticQuery, graphql, Link } from "gatsby";
import { cn, Icon, IconChevron, InlineText } from "../../lib/src";
import { version as labVersion } from "../../lab/package.json";
import { version as libVersion } from "../../lib/package.json";
import {
	SearchNavigation,
	SearchableNavigationItem,
} from "../../lab/src/components/navigation/search-navigation/search-navigation";
import {
	isButtonItem,
	isLinkItem,
} from "../../lab/src/components/navigation/side-navigation/side-navigation-utils";
import { Splitter } from "./docs-splitter";
import * as scss from "./docs-sidemenu.scss";

type MenuItem = {
	id: string;
	href: string;
	name: string;
	env?: string;
	type?: string;
	category?: string;
	deprecated?: boolean;
};

const TOP_LEVEL_KEY = "TOP_LEVEL_KEY";
const GROUP_ORDER = ["Developing", "Writing", "Designing", TOP_LEVEL_KEY, "Components"];
function getGroupOrder(group: string) {
	const idx = GROUP_ORDER.indexOf(group);
	return idx === -1 ? GROUP_ORDER.length : idx;
}

function useAllPagePaths(): {
	node: { path: string; context: { deprecated: boolean } };
}[] {
	const data = useStaticQuery(graphql`
		query SideMenuQuery {
			allSitePage {
				edges {
					node {
						path
						context {
							deprecated
						}
					}
				}
			}
		}
	`);

	return data.allSitePage.edges;
}

export function StyleguideSideMenu(props: { pageUrl: string | undefined }): JSX.Element {
	const navRef = useRef<HTMLElement>(null);
	const pageUrl = props.pageUrl ?? "";

	const allSitePage = useAllPagePaths();

	const records: Record<string, { deprecated: boolean } | undefined> = allSitePage
		.filter((item) => !item.node.path.includes("404") && item.node.path !== "/")
		.reduce((list, item) => {
			list[item.node.path] = item.node.context;
			return list;
		}, {} as Record<string, { deprecated: boolean } | undefined>);

	const links = Object.keys(records);

	const groups = links.reduce((acc, link) => {
		const match = link.match(/^\/([^\/]+)\/.+/); // first segment, but only if multiple segments `/cat/page` = cat; `/page/` = null
		let group = (match && match[1]) ?? TOP_LEVEL_KEY;
		if (group == "lib" || group == "lab") {
			group = "Components";
		}
		(acc[group] ?? (acc[group] = [])).push(link);
		return acc;
	}, {} as Record<string, string[]>);

	const menuItems = Object.entries(groups)
		.map(([group, links]) => [group, links.map(urlToMenuItem).sort(cmpTypeCategoryName)] as const)
		.sort(([a], [b]) => getGroupOrder(a) - getGroupOrder(b));

	const [navVisible, setNavVisible] = useState(true);

	// Transform the menuItems into a hierarchical structure suitable for search
	const searchableMenuItems: SearchableNavigationItem[] = [];
	menuItems.forEach(([group, items]) => {
		if (group === TOP_LEVEL_KEY) {
			searchableMenuItems.push(
				...items.map((i) => ({
					id: i.id,
					title: i.name,
					href: i.href,
				}))
			);
		} else {
			const categories = [
				...new Set(items.filter((i) => i.category).map((i) => i.category!)),
			].sort();
			if (categories.length == 0) {
				searchableMenuItems.push({
					id: `group-${searchableMenuItems.length}}`,
					title: group,
					children: items.map((i) => ({
						id: i.id,
						title: i.name,
						href: i.href,
					})),
				});
			} else {
				for (const c of categories) {
					const subitems = items
						.filter((i) => i.category == c)
						.map((i) => ({
							id: i.id,
							title: i.name,
							href: i.href,
						}));
					searchableMenuItems.push({
						id: `group-${searchableMenuItems.length}}`,
						title: group,
						children: [
							{ id: `subgroup-${searchableMenuItems.length}`, title: c, children: subitems },
						],
					});
				}
			}
		}
	});

	// The components titles are in a mix of cases; capitalize them all
	function Capitalize(items: SearchableNavigationItem[]) {
		items.forEach((x) => {
			x.title = x.title.replace(/\b\w/g, (w) => w.toUpperCase());
			if (x.children) Capitalize(x.children);
		});
	}
	Capitalize(searchableMenuItems);

	const toggleNav = () => {
		if (matchMedia("(max-width: 35rem)").matches) {
			setNavVisible((prevNavVisible) => !prevNavVisible);
		}
	};

	return (
		<>
			<button
				className={scss.navToggle}
				onClick={toggleNav}
				aria-controls="nav"
				aria-expanded={navVisible ? "true" : "false"}
			>
				Menu
			</button>
			<nav id="nav" ref={navRef} className={cn(scss.nav, navVisible && scss.hidden)}>
				<div className={scss.fixerGuy}>
					<Link to="/">
						<div className={cn(scss.topGuy, scss.level1)}>
							<InlineText
								lineHeight="multi-line"
								size="xLarge"
								tone="neutralLight"
								emphasis="strong"
							>
								Fancy
							</InlineText>
							<InlineText lineHeight="multi-line" size="xLarge" tone="neutralLight">
								Design System
							</InlineText>
						</div>
					</Link>
					<div className={cn(scss.level1, scss.versionBadgeContainer)}>
						<Link to="/Developing/lib-changelog">
							<div className={cn(scss.versionBadge)}>
								<InlineText size="small" tone="neutralLight">
									FancyLib &nbsp;
									<InlineText size="small" tone="neutralLight" emphasis="strong">
										{libVersion}
									</InlineText>
								</InlineText>
							</div>
						</Link>
						<Link to="/Developing/lab-changelog">
							<div className={cn(scss.versionBadge)}>
								<InlineText size="small" tone="neutralLight">
									FancyLab &nbsp;
									<InlineText size="small" tone="neutralLight" emphasis="strong">
										{labVersion}
									</InlineText>
								</InlineText>
							</div>
						</Link>
					</div>
					<div className={cn(scss.searchContainer, scss.level1)}>
						<SearchNavigation
							hotkey="K"
							label="SEARCH"
							placeholder="Search Fancy documentation"
							items={searchableMenuItems}
							navigate={(item) => {
								if (isLinkItem(item)) {
									navigate(item.href);
								} else if (isButtonItem(item)) {
									item.onClick();
								} else {
									throw new Error(`Unhandled item ${item}`);
								}
							}}
							className="fancy-root" //we need to set fancyRoot styles since we portal out of the root
						/>
					</div>
					{menuItems.map(([group, items]) =>
						group === TOP_LEVEL_KEY ? (
							items.map((item) => (
								<MenuLink
									key={item.href}
									{...item}
									className={scss.level1}
									pageUrl={pageUrl}
									onClick={toggleNav}
									deprecated={records[item.href]?.deprecated}
								/>
							))
						) : (
							<Expander
								key={group}
								title={group}
								containsCurrent={items.some((i) => i.href === pageUrl)}
							>
								{items.map((item, idx) => (
									<React.Fragment key={item.href}>
										{(idx === 0 || item.category !== items[idx - 1].category) && item.category && (
											<div className={cn(scss.menuItem, scss.level2)}>{item.category}</div>
										)}
										<MenuLink
											{...item}
											className={scss.level2}
											pageUrl={pageUrl}
											onClick={toggleNav}
											deprecated={records[item.href]?.deprecated}
										/>
									</React.Fragment>
								))}
							</Expander>
						)
					)}
				</div>
				<Splitter
					className={scss.splitter}
					onDrag={(e) => navRef.current!.style.setProperty("--sidemenu-size", e.pageX + "px")}
				/>
			</nav>
		</>
	);
}

const collator = new Intl.Collator("en", { sensitivity: "base" });
function cmpTypeCategoryName(a: MenuItem, b: MenuItem): number {
	let t = 0;
	if (a.type && b.type) {
		t = collator.compare(a.type, b.type);
	}
	if (t === 0 && a.category && b.category) {
		t = collator.compare(a.category, b.category);
	}
	if (t === 0) {
		t = collator.compare(a.name, b.name);
	}
	return t;
}

function nameToId(name: string): string {
	return name.replace(/[^a-z0-9]/gi, "-").toLowerCase();
}

function urlToMenuItem(href: string): MenuItem {
	const parts = href.split("/");
	if (parts.length === 3) {
		const name = (parts[2] || parts[1]).replace(/[-|/]/g, " ").trim();
		return { id: nameToId(name), href, name };
	}

	// first item is empty since url starts with /
	const [, env, type, ...rest] = parts;
	const [category, name] = rest.length === 1 ? [undefined, rest[0]] : rest;

	return { id: nameToId(name), href, env, type, category, name };
}

function MenuLink(props: { pageUrl: string; className?: string; onClick?: () => void } & MenuItem) {
	return (
		<Link
			to={props.href}
			className={cn(
				scss.menuItem,
				scss.clickable,
				props.href === props.pageUrl && scss.current,
				props.className
			)}
			onClick={props.onClick}
		>
			<div>{props.name}</div>
			<div className={scss.menuItemBadgeContainer}>
				{props.deprecated && <div className={cn(scss.badgy, scss.deprecatedBadge)}>Deprecated</div>}
				{props.env && (
					<div className={cn(scss.badgy, props.env === "lab" && scss.labBadge)}>{props.env}</div>
				)}
			</div>
		</Link>
	);
}

function Expander(props: { title: string; containsCurrent: boolean; children: ReactNode }) {
	const [open, setOpen] = useState(props.containsCurrent);
	const ref = useRef<HTMLDivElement>(null);
	const ownRender = useRef(false);

	const toggle = () => {
		setOpen((s) => !s);
		ownRender.current = true;
	};

	useEffect(() => {
		if (ownRender.current) {
			ownRender.current = false;
			return;
		}
		if (props.containsCurrent && !open) {
			setOpen(true); //if we are rendered by our parent and we contain the current selection, we open.
		}
	});

	return (
		<>
			<button
				className={cn(
					scss.expander,
					scss.menuItem,
					scss.clickable,
					scss.level1,
					!open && props.containsCurrent && scss.containsCurrent
				)}
				onClick={toggle}
			>
				{props.title}
				<Icon rotation={open ? "180" : "0"}>
					<IconChevron />
				</Icon>
			</button>
			<div
				ref={ref}
				style={{ maxHeight: open ? ref.current?.scrollHeight : 0 }}
				className={cn(scss.expanderList, open && scss.shown)}
			>
				{props.children}
			</div>
		</>
	);
}
