import React, { useState, useRef, useEffect } from "react";
import { cn, Spinner } from "../../lib/src";
import { Splitter } from "./docs-splitter";
import * as scss from "./docs-notepad.scss";

export function DocsNotepad(props: { src: string }): JSX.Element {
	const [shown, setShown] = useState(false);
	const [loading, setLoading] = useState(true);
	const rootRef = useRef<HTMLDivElement>(null);
	const notepadRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setLoading(true);
	}, [props.src]);

	return (
		<div role="complementary" ref={rootRef} className={scss.root}>
			<button
				aria-label="Notes"
				className={cn(scss.button, shown && scss.shown)}
				onClick={() => setShown(!shown)}
			>
				<NotepadIcon />
			</button>
			{shown && (
				<Splitter
					onDragStart={() => (notepadRef.current!.style.transition = "unset")} //remove transition to make it follow the mouse
					onDrag={(e) => {
						const w = rootRef.current!.getBoundingClientRect().right - e.pageX + "px";
						rootRef.current!.style.setProperty("--notepad-size", w);
					}}
					onDragEnd={() => notepadRef.current!.style.removeProperty("transition")}
				/>
			)}
			<div ref={notepadRef} className={cn(scss.notepad, shown && scss.shown)}>
				{shown && (
					<div className={cn(scss.spinnerOverlay, loading && scss.loading)}>
						<Spinner variant="dark" aria-valuetext="Loading" size="large" />
					</div>
				)}
				{(shown || !loading) && (
					<iframe
						key={props.src}
						className={scss.iframe}
						src={props.src}
						onLoad={() => setLoading(false)}
					/>
				)}
			</div>
		</div>
	);
}

const NotepadIcon = () => (
	<svg height="24" width="24" viewBox="0 0 24 24" focusable="false">
		<path fill="none" d="M0 0h24v24H0z" />
		<path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
		<path d="M14 17H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z" />
	</svg>
);
