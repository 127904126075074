import * as React from "react";
import { DataObserveKey, VisualComponent } from "../../../types/fancy-base-attributes";
import {
	emphasisClass,
	sizeClass,
	toneClass,
	TypographyProps,
	lineHeightSingleLineClass,
} from "../typography";
import { cn } from "../../../utils/shorthands";
import * as scss from "./inline-text.scss";

type InlineTextProps = TypographyProps<"size" | "tone" | "emphasis" | "lineHeight"> &
	DataObserveKey &
	VisualComponent;

export function InlineText(props: InlineTextProps): JSX.Element {
	const {
		id,
		size,
		emphasis,
		tone,
		lineHeight = "single-line",
		children,
		className,
		style,
	} = props;
	return (
		<span
			data-observe-key={props["data-observe-key"]}
			data-component="inline-text"
			id={id}
			className={cn(
				scss.inlineText,
				size && sizeClass(size),
				emphasis && emphasisClass(emphasis),
				tone && toneClass(tone),
				lineHeight === "single-line" && lineHeightSingleLineClass,
				className
			)}
			style={style}
		>
			{children}
		</span>
	);
}
