import React, { useState } from "react";
import { DataObserveKey, VisualComponent } from "../../../types/fancy-base-attributes";
import { useTranslations } from "../../../context/fancy-context";
import { cn, f2u } from "../../../utils/shorthands";
import { useTimeout } from "../../../utils/hooks";
import { Icon } from "../../visuals/icons/icons";
import { IconHourglassTop } from "../../visuals/icons/icons-list";
import * as scss from "./spinner.scss";

export type SpinnerProps = {
	/** Color of the spinner */
	variant: "dark" | "light";
	/** Size of the spinner (defaults to `"medium"`) */
	size?: "small" | "medium" | "large";
	/** Hides the spinner from assistive technology  */
	presentational?: boolean;
	/** Time in milliseconds for delaying appearance (defaults to 200ms ) */
	appearanceDelay?: number;
} & DataObserveKey &
	VisualComponent;

export function Spinner(props: SpinnerProps): JSX.Element | null {
	const { className, size = "medium", variant, style, appearanceDelay = 200 } = props;
	const i18n = useTranslations();
	const initiallyVisible = appearanceDelay === 0;
	const [visible, setVisible] = useState(initiallyVisible);
	useTimeout(() => setVisible(true), appearanceDelay);
	return visible ? (
		<div
			data-observe-key={props["data-observe-key"]}
			data-component="spinner"
			className={cn(scss.spinner, scss[variant], scss[size], className)}
			style={style}
			role={f2u(!props.presentational && "img")}
			aria-label={f2u(!props.presentational) && i18n.loading}
			aria-hidden={f2u(props.presentational)}
		>
			<Icon>
				<IconHourglassTop className={cn(scss.staticFallbackIcon)} />
			</Icon>
		</div>
	) : null;
}
