/**
 * Do not edit directly
 * Generated on Tue, 12 Nov 2024 13:28:23 GMT
 */

export const ColorWhite = "#ffffff";
export const ColorGrayLightest = "#F6F6F6";
export const ColorGrayLighter = "#EDEDED";
export const ColorGrayLight = "#A6A6A7";
export const ColorGray = "#767676";
export const ColorGrayDark = "#4D4D50";
export const ColorGrayDarker = "#202124";
export const ColorGrayDarkest = "#18191B";
export const ColorBlack = "#000000";
export const ColorBlueLightest = "#9eb7ed";
export const ColorBlueLighter = "#6e94e5";
export const ColorBlueLight = "#3d70dc";
export const ColorBlue = "#0d4cd3";
export const ColorBlueDark = "#0a399e";
export const ColorBlueDarker = "#07266a";
export const ColorBlueDarkest = "#031335";
export const ColorTealLightest = "#d5fbfb";
export const ColorTealLighter = "#95f4f4";
export const ColorTealLight = "#23e8e8";
export const ColorTeal = "#1CBCBD";
export const ColorTealDark = "#169191";
export const ColorTealDarker = "#127474";
export const ColorTealDarkest = "#093a3a";
export const ColorGreenLightest = "#b3efcf";
export const ColorGreenLighter = "#8ee6b8";
export const ColorGreenLight = "#68dea0";
export const ColorGreen = "#42d688";
export const ColorGreenDark = "#32a166";
export const ColorGreenDarker = "#216b44";
export const ColorGreenDarkest = "#103622";
export const ColorYellowLightest = "#fff7d3";
export const ColorYellowLighter = "#ffea92";
export const ColorYellowLight = "#ffdc49";
export const ColorYellow = "#ffd31c";
export const ColorYellowDark = "#bf9e15";
export const ColorYellowDarker = "#806a0e";
export const ColorYellowDarkest = "#403507";
export const ColorRedLightest = "#ffb8c0";
export const ColorRedLighter = "#ff94a1";
export const ColorRedLight = "#ff7181";
export const ColorRed = "#ff4d62";
export const ColorRedDark = "#bf3a4a";
export const ColorRedDarker = "#802731";
export const ColorRedDarkest = "#401319";
export const ColorOrangeLightest = "#ffd1bb";
export const ColorOrangeLighter = "#ffba98";
export const ColorOrangeLight = "#ffa376";
export const ColorOrange = "#ff8c54";
export const ColorOrangeDark = "#bf693f";
export const ColorOrangeDarker = "#80462a";
export const ColorOrangeDarkest = "#402315";
export const ColorPurpleLightest = "#f3ebfe";
export const ColorPurpleLighter = "#d1cbfd";
export const ColorPurpleLight = "#a9a1f4";
export const ColorPurple = "#7a63c7";
export const ColorPurpleDark = "#5f4eaa";
export const ColorPurpleDarker = "#413673";
export const ColorPurpleDarkest = "#010125";
export const ColorPinkLightest = "#feebf4";
export const ColorPinkLighter = "#fdcbe1";
export const ColorPinkLight = "#f4a1b5";
export const ColorPink = "#dd4889";
export const ColorPinkDark = "#ce3f81";
export const ColorPinkDarker = "#b42367";
export const ColorPinkDarkest = "#4b112c";
export const ColorChart1 = "#0d4cd3";
export const ColorChart2 = "#1CBCBD";
export const ColorChart3 = "#0a399e";
export const ColorChart4 = "#ff8c54";
export const ColorChart5 = "#dd4889";
export const ColorChart6 = "#5f4eaa";
export const ColorChart7 = "#127474";
export const ColorChart8 = "#806a0e";
export const ColorHeatWarm1 = "#3EC67B";
export const ColorHeatWarm2 = "#42D688";
export const ColorHeatWarm3 = "#68D985";
export const ColorHeatWarm4 = "#8EDC82";
export const ColorHeatWarm5 = "#B3DF80";
export const ColorHeatWarm6 = "#D9E27D";
export const ColorHeatWarm7 = "#FFE57A";
export const ColorHeatWarm8 = "#FFC76D";
export const ColorHeatWarm9 = "#FFAA61";
export const ColorHeatWarm10 = "#FF8C54";
export const ColorHeatWarm11 = "#FF6D5B";
export const ColorHeatWarm12 = "#ff4d62";
export const ColorHeatCool1 = "#E7EDFB";
export const ColorHeatCool2 = "#CFDBF6";
export const ColorHeatCool3 = "#B6C9F2";
export const ColorHeatCool4 = "#9eb7ed";
export const ColorHeatCool5 = "#6e94e5";
export const ColorHeatCool6 = "#3d70dc";
export const ColorHeatCool7 = "#0d4cd3";
export const ColorHeatCool8 = "#0A3DA9";
export const ColorHeatCool9 = "#082E7F";
export const ColorHeatCool10 = "#051E54";
export const ColorScaleCool1 = "#3d70dc";
export const ColorScaleCool2 = "#0d4cd3";
export const ColorScaleCool3 = "#0A3DA9";
export const ColorScaleCool4 = "#082E7F";
export const ColorScaleWarm1 = "#42d688";
export const ColorScaleWarm2 = "#ffd31c";
export const ColorScaleWarm3 = "#ff8c54";
export const ColorScaleWarm4 = "#ff4d62";
export const ColorStatusPositiveDark = "#216b44";
export const ColorStatusPositiveLight = "#b3efcf";
export const ColorStatusPositiveLightForeground = "#103622";
export const ColorStatusWarningDark = "#806a0e";
export const ColorStatusWarningDarkForeground = "#ffffff";
export const ColorStatusWarningNeutral = "#ffd31c";
export const ColorStatusWarningLight = "#fff7d3";
export const ColorStatusWarningLightForeground = "#403507";
export const ColorStatusCriticalDark = "#80462a";
export const ColorStatusCriticalLight = "#FFF4EE";
export const ColorStatusCriticalLightForeground = "#80462a";
export const ColorStatusNegativeDark = "#bf3a4a";
export const ColorStatusNegativeLight = "#FFEDEF";
export const ColorStatusNegativeLightForeground = "#bf3a4a";
export const ColorStatusSubtleDark = "#4D4D50";
export const ColorStatusSubtleLight = "#EDEDED";
export const ColorStatusSubtleLightForeground = "#4D4D50";
export const ColorStatusNeutralDark = "#0d4cd3";
export const ColorStatusNeutralLight = "#E7EDFB";
export const ColorStatusNeutralLightForeground = "#0d4cd3";
export const ColorStatusHighlight1Dark = "#127474";
export const ColorStatusHighlight1Light = "#d5fbfb";
export const ColorStatusHighlight1LightForeground = "#093a3a";
export const ColorStatusHighlight2Dark = "#ce3f81";
export const ColorStatusHighlight2Light = "#feebf4";
export const ColorStatusHighlight3Dark = "#7a63c7";
export const ColorStatusHighlight3Light = "#f3ebfe";
export const ColorBorderSeparator = "#EDEDED";
export const ColorBorderInteractiveDefault = "#767676";
export const ColorBorderInteractiveError = "#ff4d62";
export const ColorBorderInteractiveHover = "#0d4cd3";
export const ColorBorderInteractiveActive = "#0d4cd3";
export const ColorBorderInteractiveSelected = "#0d4cd3";
export const ColorBorderInteractiveDisabled = "#EDEDED";
export const ColorBackgroundMainPage = "#F7F8FD";
export const ColorBackgroundInteractiveDefault = "#ffffff";
export const ColorBackgroundInteractiveHover = "#F6F6F6";
export const ColorBackgroundInteractiveActive = "#CFDBF6";
export const ColorBackgroundInteractiveSelected = "#E7EDFB";
export const ColorBackgroundInteractiveDisabled = "#F6F6F6";
export const ColorBackgroundStaticPrimary = "#ffffff";
export const ColorBackgroundStaticSecondary = "#F6F6F6";
export const ColorToastBackgroundWarning = "#ffd31c";
export const ColorToastColorWarning = "#403507";
export const ColorToastForegroundWarning = "#403507";
export const ColorButtonCtaSecondaryNotActive = "#07266a";
export const ColorButtonCtaSecondaryActive = "#031335";
export const ColorButtonSecondaryNotActive = "#07266a";
export const ColorButtonSecondaryNotActiveHoverBorder = "#07266a";
export const ColorButtonSecondaryActive = "#031335";
export const ColorTypographyToneSubtle = "#4D4D50";
export const ColorTypographyTonePositive = "#216b44";
export const ColorTypographyToneNegative = "#bf3a4a";
export const ColorTypographyToneWarning = "#80462a";
export const ColorTextInteractiveDarkDefault = "#202124";
export const ColorTextInteractiveLightDefault = "#ffffff";
export const ColorTextInteractiveDisabled = "#767676";
export const ColorTextStaticHeader = "#202124";
export const ColorTextStaticBody = "#202124";
export const ColorNavigationBackground = "#202124";
export const ColorNavigationHover = "#4D4D50";
export const ColorNavigationActive = "#4D4D50";
export const ColorSearchNavigationTextDefault = "#ffffff";
export const ColorSearchNavigationBackgroundDefault = "#202124";
export const ColorSearchNavigationHoverOutline = "#4D4D50";
export const ColorSearchNavigationActiveOutline = "#ffffff";
export const ColorSearchNavigationResultsSectionBorder = "#4D4D50";
export const ColorSearchNavigationResultsItemHover = "#4D4D50";
export const ColorSearchNavigationResultsItemActive = "#4D4D50";
export const FontFamilyDefault = "'Inter', arial, sans-serif";
export const FontFamilyAlternative = "'Inter', arial, sans-serif";
export const FontFamilyCode = "'Roboto Mono', courier, sans-serif";
export const FontFamilyHeading = "'Geologica', arial, sans-serif";
export const FontFamilyDisplay = "'Geologica', arial, sans-serif";
export const FontSizeXxSmall = "0.625rem";
export const FontSizeXSmall = "0.75rem";
export const FontSizeSmall = "0.875rem"; // body
export const FontSizeMedium = "1rem";
export const FontSizeLarge = "1.125rem";
export const FontSizeXLarge = "1.375rem";
export const FontSizeXxLarge = "1.6875rem";
export const FontSizeXxxLarge = "2rem";
export const FontSizeInteractiveDefault = "0.875rem";
export const FontWeightNormal = 400;
export const FontWeightMedium = 500;
export const FontWeightBold = 700;
export const FontWeightInteractiveDefault = 400;
export const FontWeightInteractiveSelected = 500;
export const LineHeightReset = "1";
export const LineHeightXSmall = "1.1"; // title/url cell
export const LineHeightSmall = "1.3"; // text
export const LineHeightMedium = "1.4";
export const LineHeightLarge = "1.5";
export const LineHeightXLarge = "1.6";
export const SizeButtonSmall = "1.375rem";
export const SizeButtonDefault = "2rem";
export const SizeButtonLarge = "2.5rem";
export const SizeIconSmall = "0.75rem";
export const SizeIconMedium = "1rem";
export const SizeIconDefault = "1.25rem";
export const SizeIconResponsive = "1.25em";
export const SizeIllustrationSmall = "6rem";
export const SizeIllustrationMedium = "8rem";
export const SizeIllustrationDefault = "12rem";
export const SizePillSmall = "1.25rem";
export const SizePillDefault = "1.375rem";
export const SizePillLarge = "1.5rem";
export const SizeShadowFocusInner = "0 0 0 0.125rem";
export const SizeShadowFocusOuter = "0 0 0 0.25rem";
export const SizeShadowHover = "0 0 0 0.1875rem";
export const SpaceXxSmall = "0.25rem";
export const SpaceXSmall = "0.5rem";
export const SpaceSmall = "0.75rem";
export const SpaceMedium = "1rem";
export const SpaceLarge = "1.25rem";
export const SpaceXLarge = "1.5rem";
export const SpaceXxLarge = "2rem";
export const SpaceXxxLarge = "2.5rem";
export const BorderRadiusSmall = "0.1875rem";
export const BorderRadiusMedium = "0.3125rem";
export const BorderRadiusRounded = "125rem";
export const OpacityShadowHover = "0.2";
export const ShadowLayerLow = "0 0.125rem 0.25rem 0 rgba(31, 43, 63, 0.08), 0 0.0625rem 0.125rem 0 rgba(31, 43, 63, 0.2)"; // rgba value equals color.gray--darker with 0.08/0.2 opacity
export const ShadowLayerMedium = "0 0.25rem 0.5rem 0 rgba(31, 43, 63, 0.12), 0 0.125rem 0.25rem 0 rgba(31, 43, 63, 0.08)"; // rgba value equals color.gray--darker with 0.12/0.08 opacity
export const ShadowLayerHigh = "0 0.5rem 0.75rem 0 rgba(31, 43, 63, 0.16), 0 0.25rem 0.375rem 0 rgba(31, 43, 63, 0.04)"; // rgba value equals color.gray--darker with 0.16/0.04 opacity
export const ShadowFocusBase = "0 0 0 0.125rem #ffffff";
export const ShadowFocusDefault = "0 0 0 0.125rem #ffffff, 0 0 0 0.25rem #0d4cd3";
export const ShadowHoverDefault = "0 0 0 0.1875rem rgba(27, 78, 161, 0.2)"; // rgba value equals color.teal with opacity.shadow--hover
export const ShadowHoverError = "0 0 0 0.1875rem rgba(232, 70, 94, 0.2)"; // rgba value equals color.red with opacity.shadow--hover
export const ShadowHoverLeft = "inset 0.1875rem 0 0 0 #A6A6A7";
export const ShadowSelectedBottom = "inset 0 -0.1875rem 0 0 #0d4cd3";
export const ShadowSelectedLeft = "inset 0.1875rem 0 #0d4cd3";
export const LayoutSmall = "36rem";
export const LayoutMedium = "48rem";
export const LayoutLarge = "62rem";
export const LayoutXLarge = "75rem";
export const LayoutXxLarge = "100rem";
export const AnimationFast = "0.1s";
export const AnimationMedium = "0.2s";
export const AnimationSlow = "0.3s";
