import React, { ReactElement } from "react";
import { cn } from "../../../utils/shorthands";
import * as scss from "./icons.scss";

export type IconProps = {
	/** A icon to be cloned and applied relevant styling to */
	children: ReactElement<IconElementProps>;
	/** Controls the width and height of the component, defaults to `"large"` */
	size?: "small" | "medium" | "large" | "responsive";
	/** How many degrees the icon should be rotated, will be animated if changed from one value to another */
	rotation?: Rotation;
	/** If the icon should have another color */
	fill?: string;
};

type Rotation = "0" | "90" | "180" | "270";

export function Icon({ children, size = "large", rotation, fill }: IconProps): ReactElement {
	return React.cloneElement(children, {
		className: cn(
			"fancy-icon",
			scss[size],
			rotation !== undefined && degToClass(rotation),
			children.props.className
		),
		fill,
	});
}

/** Helper to create SVG components with correct outer markup */
export function svgIcon(icon: JSX.Element): (props: IconElementProps) => JSX.Element {
	return ({ className, fill }) => (
		<svg
			data-component="icon"
			className={className}
			style={{ fill }}
			viewBox="0 0 24 24"
			focusable="false"
			aria-hidden="true"
			fill={"currentColor"}
		>
			{icon}
		</svg>
	);
}

function degToClass(rotation: Rotation) {
	switch (rotation) {
		case "0":
			return scss.deg0;
		case "90":
			return scss.deg90;
		case "180":
			return scss.deg180;
		case "270":
			return scss.deg270;
	}
}

export type IconElementProps = {
	className?: string;
	fill?: string;
};
