import React, { useState, useEffect, useRef } from "react";
import {
	IconSearch,
	cn,
	VisualComponent,
	DataObserveKey,
	useUniqueId,
} from "@siteimprove/fancylib";
import { TextHighlight } from "../../text/text-highlight/text-highlight";
import { useLabTranslations } from "../../../translations/translations";
import { useDesignToken, useThemedPortal } from "../../context/theme/theme";
import { keyCodeToListPosition } from "../../../utils/keyboard-nav-utils";
import {
	NavigateFn,
	NavigationItem,
	isButtonItem,
	isLinkItem,
} from "../side-navigation/side-navigation-utils";
import * as scss from "./search-navigation.scss";

export type SearchNavigationProps = {
	/** List of strings or objects to display in the menu */
	items: SearchableNavigationItem[];
	/** Optional label text of the search button */
	label?: string;
	/** Optional placeholder text of the search field */
	placeholder?: string;
	/** Optional character of hotkey for invoking the search */
	hotkey?: string;
	/** Custom navigation function, in case the user has a better way of navigating than setting window.location.href */
	navigate?: NavigateFn<React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>>;
	/** Optional list of selectors to identify dialogs (e.g. modals and sidepanels) that should disable the hotkey */
	dialogSelectors?: string[];
} & DataObserveKey &
	VisualComponent;

export type SearchableNavigationItem = NavigationItem;

type SearchableItem = {
	path: string[];
	item: SearchableNavigationItem;
};

function defaultNavigateTo(href: string) {
	window.location.href = href;
}

const ctrlKeyName =
	typeof window === "undefined"
		? undefined
		: !navigator.platform.toLowerCase().startsWith("mac")
		? "CTRL"
		: "⌘";

export function SearchNavigation(props: SearchNavigationProps): JSX.Element {
	const { items, label, placeholder, hotkey, navigate, dialogSelectors, className } = props;

	const [shown, setShown] = useState(false);
	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const prevFocus = useRef<HTMLElement | null>(null);
	const { ColorWhite } = useDesignToken();

	const hotkeyCharacter = hotkey && hotkey.length == 1 ? hotkey[0].toUpperCase() : undefined;

	const dialogElementsVisible = () => {
		const fancyDialogSelectors = ["[data-component='modal']", "[data-component='side-panel']"];
		const combinedDialogSelectors = [...(dialogSelectors || []), ...fancyDialogSelectors];
		const dialogElements = document.querySelectorAll(combinedDialogSelectors.join(","));
		return dialogElements.length > 0;
	};

	useEffect(() => {
		const listener = (e: KeyboardEvent) => {
			if (e.key.toUpperCase() === hotkeyCharacter && (e.ctrlKey || e.metaKey)) {
				e.preventDefault();
				// Only open search modal if there's no visible dialog elements
				!dialogElementsVisible() &&
					setShown((s) => {
						// Remember current focus based on current state of shown. We can't just use
						// "shown" directly in this listener as its value will have been captured as
						// false in the very first call to useEffect(..., [])
						if (!s) {
							prevFocus.current = document.activeElement as HTMLElement;
						}
						return !s;
					});
			}
		};
		document.addEventListener("keydown", listener);
		return () => document.removeEventListener("keydown", listener);
	}, []);

	useEffect(() => {
		if (!shown) {
			prevFocus.current?.focus();
		}
	}, [shown]);

	const hasHotkey = ctrlKeyName && hotkeyCharacter;
	const hotkeyLabel = hasHotkey ? `(${ctrlKeyName} + ${hotkeyCharacter})` : undefined;
	const ariaKeyShortcut = hasHotkey ? `${ctrlKeyName}+${hotkeyCharacter}` : undefined;
	const i18n = useLabTranslations();
	const searchLabel = label ?? i18n.searchInNavigationLabel;
	const searchPlaceholder = placeholder ?? i18n.searchInNavigationPlaceholder;

	// Flatten the hierarchical menu into a flat, searchable list
	const searchables = flattenActions(items);
	function flattenActions(
		items: SearchableNavigationItem[],
		parentNames: string[] = []
	): SearchableItem[] {
		let flattened: SearchableItem[] = [];
		for (const item of items) {
			if (item.children && item.children.length > 0) {
				const subtree = flattenActions(item.children, [...parentNames, item.title]);
				flattened = flattened.concat(subtree);
			} else {
				const path = [...parentNames, item.title];
				flattened.push({ path, item });
			}
		}
		return flattened;
	}

	return (
		<div className={scss.searchField}>
			<button
				ref={buttonRef}
				onClick={() => {
					prevFocus.current = document.activeElement as HTMLElement;
					setShown(true);
				}}
				disabled={shown}
				aria-keyshortcuts={ariaKeyShortcut}
				aria-expanded={shown ? "true" : "false"}
				data-tweak-stayopen={false} // change at runtime in browser devtools to make popover stay open
				data-observe-key={props["data-observe-key"]}
			>
				<IconSearch fill={ColorWhite} />
				<span>{searchLabel}</span>
				{hotkeyLabel && (
					<span className={scss.searchFieldHotkey} aria-hidden="true">
						{hotkeyLabel}
					</span>
				)}
			</button>
			<SearchPopover
				shown={shown}
				placeholder={searchPlaceholder}
				onClose={() => setShown(false)}
				stayOpen={buttonRef.current?.getAttribute("data-tweak-stayopen") == "true"}
				items={searchables}
				className={className}
				navigate={navigate}
			/>
		</div>
	);
}

function SearchPopover(props: {
	shown: boolean;
	placeholder: string;
	onClose: () => void;
	stayOpen: boolean;
	items: SearchableItem[];
	className?: string;
	navigate?: NavigateFn<React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>>;
}) {
	//document does not exist in SSR and we use it as dropzone
	if (typeof document === "undefined") {
		return null;
	} else {
		return (
			<SearchPopoverImpl
				shown={props.shown}
				placeholder={props.placeholder}
				onClose={props.onClose}
				stayOpen={props.stayOpen}
				items={props.items}
				className={props.className}
				navigate={props.navigate}
			/>
		);
	}
}

// Using a separate Impl function to avoid conditionally calling React hooks
function SearchPopoverImpl(props: {
	shown: boolean;
	placeholder: string;
	onClose: () => void;
	stayOpen: boolean;
	items: SearchableItem[];
	className?: string;
	navigate?: NavigateFn<React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>>;
}) {
	const { shown, placeholder, onClose, stayOpen, items, className, navigate } = props;

	const [query, setQuery] = useState("");
	const [idx, setIdx] = useState(0);
	const [results, setResults] = useState<SearchableItem[]>([]);
	const [mount, setMount] = useState(shown);
	const inputRef = useRef<HTMLInputElement>(null);
	const selectedResultRef = useRef<HTMLDivElement | null>(null);
	const idForSearchBar = useUniqueId("search-bar");
	const idForResults = useUniqueId("results");
	const idForSelectedResult = useUniqueId("selected-result");
	const { ColorWhite } = useDesignToken();

	useEffect(() => {
		if (shown) {
			setQuery("");
			inputRef.current?.focus();
		}
	}, [shown]);

	useEffect(() => {
		setIdx(0);
	}, [results]);

	useEffect(() => {
		// For now, searching simply checks if the menu-item contain the search query
		const q = query.toLowerCase();
		setResults(q ? items.filter((x) => x.item.title.toLowerCase().includes(q)) : []);
	}, [query]);

	useEffect(() => {
		// Bring the selected element into view
		const elem = selectedResultRef.current;
		elem?.scrollIntoView(false);
	}, [idx]);

	// The searchable items should appear "grouped" into sections based on their
	// top-level menu item. We accomplish this by having a separate data structure
	// for these sections, indexed by the top item in that section.
	// It's a tad inelegant but it's useful to avoid littering the item-list itself
	// with these unrelated headings so it can be handled as a pure list of items.
	const sectionNames = [...new Set(results.map((i) => i.path[0]))];
	const resultSections = Object.fromEntries(
		sectionNames.map((s) => [results.findIndex((r) => r.path[0] == s), s])
	);

	function activateSelectedItem(
		e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
		index: number
	) {
		const item = results[index].item;
		onClose();
		if (navigate) {
			navigate(item, e);
		} else {
			if (isLinkItem(item)) {
				item.openNew ? window.open(item.href, "_blank") : defaultNavigateTo(item.href);
			} else if (isButtonItem(item)) {
				item.onClick();
			} else {
				throw new Error(`Unhandled item ${item}`);
			}
		}
	}

	function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.key === "Tab") {
			e.preventDefault();
		}
		if (e.key === "Enter" && results[idx]) {
			e.preventDefault();
			activateSelectedItem(e, idx);
		}
		if (e.key === "Escape") {
			e.preventDefault();
			onClose();
		}

		const newIdx = keyCodeToListPosition("vertical", e.keyCode, idx, results.length - 1);

		if (newIdx !== null) {
			e.preventDefault();
			setIdx(newIdx);
		}
	}

	function onBlur() {
		if (stayOpen) return;
		onClose();
	}

	const shouldMount = shown || mount;
	const hasResults = results.length > 0;

	return useThemedPortal(
		<div
			className={cn(className, scss.searchModal, shown && scss.shown)}
			aria-modal={true} // The search is essentially a modal dialog
			onTransitionEnd={() => {
				if (!shown) {
					setQuery("");
					setIdx(0);
				}
				setMount(shown);
			}}
		>
			{shouldMount && (
				<>
					<div className={scss.searchBar} id={idForSearchBar}>
						<IconSearch fill={ColorWhite} />
						<input
							ref={inputRef}
							value={query}
							placeholder={placeholder}
							type="search"
							role="combobox" // The input controls another element, the results
							aria-autocomplete="list" // Show suggestions in popup but don't autocomplete
							aria-haspopup="listbox" // The popup is a listbox
							aria-controls={idForResults} // The popup being controlled
							aria-activedescendant={hasResults ? idForSelectedResult : ""} // The "selected" result
							aria-expanded={hasResults ? true : false}
							onChange={(e) => setQuery(e.target.value)}
							onKeyDown={onKeyDown}
							onBlur={onBlur}
						/>
					</div>
					<div
						role="listbox"
						id={idForResults}
						className={scss.results}
						aria-labelledby={idForSearchBar}
					>
						{results.map((x, i) => (
							<div key={i}>
								{resultSections[i] && (
									<div role="separator" aria-hidden className={scss.resultSection}>
										<div>{resultSections[i]}</div>
									</div>
								)}
								<div
									role="option"
									ref={i === idx ? selectedResultRef : undefined}
									id={i === idx ? idForSelectedResult : undefined}
									aria-selected={i === idx ? true : false}
									onClick={(e) => activateSelectedItem(e, i)}
									className={cn(scss.resultItem, i === idx && scss.selected)}
								>
									<div className={scss.resultItemTitle}>
										<TextHighlight needle={query} value={x.item.title} colorTheme="dark" />
									</div>
									<div className={scss.resultItemPath}>{x.path.join(" / ")}</div>
								</div>
							</div>
						))}
					</div>
				</>
			)}
		</div>,
		document.body
	);
}
