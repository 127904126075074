import React from "react";

export function Splitter(props: {
	onDrag: (e: MouseEvent) => void;
	onDragStart?: () => void;
	onDragEnd?: () => void;
	className?: string;
}): JSX.Element {
	return (
		<div
			className={props.className}
			style={{ width: "5px", cursor: "col-resize", zIndex: 1 }}
			onMouseDown={() => {
				document.body.style.pointerEvents = "none"; //important to disable move events since the iframes will intercept mouesup
				document.body.style.userSelect = "none";

				if (props.onDragStart) props.onDragStart();

				window.addEventListener("mousemove", props.onDrag);
				window.addEventListener(
					"mouseup",
					() => {
						document.body.style.removeProperty("pointer-events");
						document.body.style.removeProperty("user-select");

						if (props.onDragEnd) props.onDragEnd();

						window.removeEventListener("mousemove", props.onDrag);
					},
					{ once: true }
				);
			}}
		/>
	);
}
