import React, { ReactNode, useContext, useLayoutEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { addErrorListener } from "../../lab/src/utils/error-utils";
import { StyleguidePageProps } from "./docs-types";
import { StyleguideSideMenu } from "./docs-sidemenu";
import { DocsNotepad } from "./docs-notepad";
import * as scss from "./docs-layout.scss";

const styleguideCtx = React.createContext(null as StyleguidePageProps["pageContext"] | null);
export const useStyeguideCtx = (): StyleguidePageProps["pageContext"] => {
	const ctx = useContext(styleguideCtx);
	if (!ctx) throw "Must be used as a child to StyleguideLayout";
	return ctx;
};

function urlToPageName(url: string | undefined): string {
	if (!url) return "Design System";
	const split = url.split("/");
	const last = split[split.length - (url.endsWith("/") ? 2 : 1)]; //skip last segment if it is dash
	return last
		.split(/[ -]/) //split on space or dash
		.map((w) => w.charAt(0).toUpperCase() + w.slice(1)) //capitalize first letter of word
		.join(" ");
}

//This is referenced in gatsby-config -> gatsby-plugin-layout and is used as the layout component (wrapper) for all gatsby pages
export default function (props: StyleguidePageProps & { children: ReactNode }): JSX.Element {
	const mainRef = useRef<HTMLDivElement>(null);
	useLayoutEffect(() => mainRef.current!.scrollTo(0, 0)); //reset scroll on render/navigation

	return (
		<styleguideCtx.Provider value={props.pageContext}>
			<Helmet
				title={`Fancy - ${urlToPageName(props.pageContext.pageUrl)}`}
				htmlAttributes={{ lang: "en" }}
			/>
			<main className={`${scss.root} fancy-root`}>
				<a className={scss.skipToMain} href="#main-content">
					Skip to content
				</a>
				<StyleguideSideMenu pageUrl={props.pageContext.pageUrl} />
				<div id="main-content" ref={mainRef} className={scss.main}>
					{props.children}
				</div>
				{props.pageContext.notepad && <DocsNotepad src={props.pageContext.notepad} />}
			</main>
		</styleguideCtx.Provider>
	);
}

if (typeof document !== "undefined") {
	document.body.classList.add("fancy-root");
}

//Analytics Script
const SZ_ID = "SI_ANALYTICS";
if (typeof document !== "undefined" && !document.getElementById(SZ_ID)) {
	const sz = document.createElement("script");
	sz.type = "text/javascript";
	sz.async = true;
	sz.src = "//siteimproveanalytics.com/js/siteanalyze_6276094.js";
	sz.id = SZ_ID;
	document.body.appendChild(sz);
}

//Adding error listener
addErrorListener("docs-layout", (msg) => console.error(msg));
