import * as React from "react";
import { VisualComponent } from "@siteimprove/fancylib";
import { normalizeAccent as accentFold } from "../../../utils/accent-normalize";
import * as scss from "./text-highlight.scss";

export type ColorTheme = "dark" | "light";

type TextHighlightProps = {
	needle: string | null;
	value: string | null;
	caseSensitive?: boolean;
	colorTheme?: ColorTheme;
} & VisualComponent;

export function TextHighlight(props: TextHighlightProps): JSX.Element {
	const { needle, value, caseSensitive, colorTheme } = props;

	if (value === null) {
		return <></>;
	}

	if (!needle) {
		return <>{value}</>;
	}

	const theme = colorTheme === "dark" ? scss.highlightDark : scss.highlightLight;

	let normalizedValue = accentFold(value);
	let normalizedNeedle = accentFold(needle);

	if (!caseSensitive) {
		normalizedValue = normalizedValue.toLowerCase();
		normalizedNeedle = normalizedNeedle.toLowerCase();
	}

	const splits = normalizedValue.split(normalizedNeedle);

	const displays: React.ReactNode[] = [];

	let index = 0;
	for (let i = 0; i < splits.length; i++) {
		const cur = splits[i];

		displays.push(value.substring(index, index + cur.length));

		index += cur.length;
		if (i + 1 < splits.length) {
			displays.push(
				<mark className={theme} key={i}>
					{value.substring(index, index + needle.length)}
				</mark>
			);
			index += needle.length;
		}
	}

	return <>{displays}</>;
}
